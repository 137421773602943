import { DateTime } from "luxon";
import sha256 from "crypto-js/sha1";
import Hex from "crypto-js/enc-hex";

const generateAdminToken = () => {
  const timestamp = DateTime.now().startOf("hour").toUnixInteger();

  return Hex.stringify(
    sha256(process.env.REACT_APP_ADMIN_TOKEN_PREFIX + "-" + timestamp.toString())
  );
};

const adminTokenUtils = {
  generateAdminToken,
};

export default adminTokenUtils;
