import { Grid } from "@mui/material";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { ControlledFormInput } from "../../../../common/components/ui";
import { StoresFormStepProps } from "../../stores-form-screen.component";
import { ControlledSocialMediaSelect } from "../social-media-select/social-media-select.component";
import storesFormStepsConstants from "./stores-form-steps.constants";

const StoresFormStepFour: FC<StoresFormStepProps> = () => {
  const { control } = useFormContext();

  return (
    <Grid container mt={3}>
      <Grid item md={6} xs={12}>
        <Grid item xs={12} sm={10} mb={2}>
          <ControlledFormInput
            name="hours"
            control={control}
            label={storesFormStepsConstants.labels.hours}
          />
        </Grid>
        <Grid item xs={12} sm={10} mb={2}>
          <ControlledFormInput
            name="phoneNumber"
            control={control}
            label={storesFormStepsConstants.labels.phoneNumber}
          />
        </Grid>
        <Grid item xs={12} sm={10} mb={2}>
          <ControlledFormInput
            name="email"
            control={control}
            label={storesFormStepsConstants.labels.email}
          />
        </Grid>
        <Grid item xs={12} sm={10} mb={2}>
          <ControlledFormInput
            name="webpage"
            control={control}
            label={storesFormStepsConstants.labels.webpage}
          />
        </Grid>
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid item xs={12} sm={10} mb={2}>
          <ControlledSocialMediaSelect
            name="socialMedia"
            control={control}
            label={storesFormStepsConstants.labels.socials}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StoresFormStepFour;
