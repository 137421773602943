import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { withLayout } from "../../hocs/with-layout";
import ReviewsTable from "./components/reviews-table/reviews-table.component";
import { RecommendationsContainer } from "./reviews-screen.styles";
import reviewsScreenConstants from "./reviews-screen.constants";
import { ReviewsApiService } from "../../api/reviews.api.service";

const ReviewsScreen = () => {
  const [reviews, setReviews] = useState<UserReview[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    ReviewsApiService.getReviewsToManage().then((reviewsData) => {
      setReviews(reviewsData.data);
      setLoading(false);
    });
  }, []);

  return (
    <RecommendationsContainer>
      <Stack mt={5}>
        <ReviewsTable reviews={reviews} loading={loading} />
      </Stack>
    </RecommendationsContainer>
  );
};

export default withLayout(ReviewsScreen, {
  title: reviewsScreenConstants.title,
  card: true,
  navbar: true,
});
