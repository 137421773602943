import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { RecommendationApiService } from "../../api/recommendation.api.service";
import { withLayout } from "../../hocs/with-layout";
import RecommendationsTable from "./components/recommendations-table/recommendations-table.component";
import { RecommendationsContainer } from "./recommendations-screen.styles";
import recommendationsScreenConstants from "./recommendations-screen.constants";

const RecommendationsScreen = () => {
  const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    RecommendationApiService.getRecommendations().then((recommendationRes) => {
      setRecommendations(recommendationRes.data);
      setLoading(false);
    });
  }, []);

  return (
    <RecommendationsContainer>
      <Stack mt={5}>
        <RecommendationsTable recommendations={recommendations} loading={loading} />
      </Stack>
    </RecommendationsContainer>
  );
};

export default withLayout(RecommendationsScreen, {
  title: recommendationsScreenConstants.title,
  card: true,
  navbar: true,
});
