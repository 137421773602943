import { Alert, AlertColor, AlertProps, Snackbar, SnackbarProps } from "@mui/material";
import { createContext, useState } from "react";

interface ShowSnackbarOptions {
  snackbarProps?: SnackbarProps;
  alertProps?: AlertProps;
  severity?: AlertColor;
}

interface Actions {
  showSnackbar: (message: string, props?: ShowSnackbarOptions) => void;
}

const actions: Actions = {
  showSnackbar: (_1: string, _2?: ShowSnackbarOptions) => {},
};

export const SnackbarContext = createContext({ ...actions });

const SnackbarContextProvider = ({ children }: { children: JSX.Element }) => {
  const [message, setMessage] = useState<Nullable<string>>(null);
  const [options, setOptions] = useState<ShowSnackbarOptions>();

  const showSnackbar = (message: string, options?: ShowSnackbarOptions) => {
    setOptions(options);
    setMessage(message);
  };

  const handleCloseError = () => {
    setMessage(null);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      <Snackbar
        open={!!message}
        autoHideDuration={4000}
        onClose={handleCloseError}
        {...options?.snackbarProps}
      >
        <Alert
          onClose={handleCloseError}
          severity={options?.severity ?? "success"}
          sx={{ width: "100%" }}
          {...options?.alertProps}
        >
          {message}
        </Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarContextProvider;
