import { del, get } from "./api.service";

const endpoint = "/recommendations";

const getRecommendations = () => get<Recommendation[]>({ endpoint, auth: true });

const getRecommendationById = (recommendationId: string) =>
  get<Recommendation>({ endpoint: endpoint + "/" + recommendationId, auth: true });

const discardRecommendationById = (recommendationId: string) =>
  del({ endpoint: endpoint + "/" + recommendationId, auth: true, body: undefined });

export const RecommendationApiService = {
  getRecommendations,
  getRecommendationById,
  discardRecommendationById,
};
