import { CancelOutlined, CheckCircleRounded } from "@mui/icons-material";
import { styled, Avatar } from "@mui/material";

export const FeedbackAvatar = styled(Avatar)`
  height: 24px;
  width: 24px;
  margin-right: 5px;
  object-fit: fill;
`;

export const CancelButton = styled(CancelOutlined)`
  cursor: pointer
`

export const AcceptButton = styled(CheckCircleRounded)`
  cursor: pointer
`
