import { Facebook, Instagram, Twitter, WhatsApp } from "@mui/icons-material";

export type SocialMediaInput = {
  [key in SocialMediaName]: {
    checked: boolean;
    url: string;
    icon: JSX.Element;
    label: string;
  };
};

export const socialMedias: SocialMediaInput = {
  facebook: { icon: <Facebook />, label: "Facebook", url: "", checked: false },
  twitter: { icon: <Twitter />, label: "Twitter", url: "", checked: false },
  instagram: { icon: <Instagram />, label: "Instagram", url: "", checked: false },
  whatsapp: { icon: <WhatsApp />, label: "WhatsApp", url: "", checked: false },
};

export const socialMediaInputToBody = (socialMedias: SocialMediaInput) => {
  const socials: SocialMedia[] = [];
  Object.entries(socialMedias).forEach(([social, data]) => {
    if (data.checked) {
      const name = social as SocialMediaName;
      socials.push({
        name,
        account: data.url,
        url: data.url,
      });
    }
  });
  return socials;
};
