import { get, post, put } from "./api.service";

const endpoint = "/coupons";

export interface CouponBody {
  title: string;
  description: Nullable<string>;
  discount: string;
  limit: number;
  expires: string;
  price: number;
}

interface CreateCouponBody extends CouponBody {
  storeId: string;
}

interface UpdateCouponBody extends CouponBody {
  active?: boolean;
}

interface QueryCoupons {
  name?: string;
  sortBy?: string;
}

const queryCouopns = (query?: QueryCoupons) =>
  get<Paginated<Coupon>>({
    endpoint,
    auth: true,
    axiosConfig: {
      params: {
        page: 1,
        limit: 9999,
        name: query?.name,
        sortBy: query?.sortBy,
      },
    },
  });

const getCouponDetails = (couponId: string) =>
  get<Coupon>({
    endpoint: endpoint + "/" + couponId,
    auth: true,
  });

const createCoupon = (body: CreateCouponBody) =>
  post({
    endpoint,
    auth: true,
    body,
  });

const updateCoupon = (couponId: string, body: UpdateCouponBody) =>
  put({
    endpoint: endpoint + "/" + couponId,
    auth: true,
    body,
  });

export const CouponApiService = {
  queryCouopns,
  createCoupon,
  updateCoupon,
  getCouponDetails,
};
