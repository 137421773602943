import { Stack, styled } from "@mui/material";

const ContentCard = styled(Stack)`
  border: 1px solid #e8e8e8;
  margin-top: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(4)};
  background-color: white;
`;

export default ContentCard;
