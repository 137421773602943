import { get } from "./api.service";

const endpoint = "/categories";

export const getCategories = () =>
  get<Paginated<Category>>({
    endpoint,
    auth: true,
    axiosConfig: {
      params: { limit: 40 },
    },
  });

export const CategoryApiService = {
  getCategories,
};
