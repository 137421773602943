import { ArrowBack } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Button, ContentCard, Layout } from "../common/components/ui";
import LayoutContextProvider from "../common/context/layout.context";
import Navbar from "../router/navbar/navbar.component";

interface LayoutProps {
  title?: string;
  card?: boolean;
  navbar?: boolean;
  back?: boolean;
}

export const withLayout = (Component: React.FC, layoutProps: LayoutProps) => (props: any) => {
  const [title, setTitle] = useState<string | undefined>(layoutProps.title);
  const navigate = useNavigate();

  return (
    <LayoutContextProvider title={title} setTitle={setTitle}>
      <Layout hasNavbar={layoutProps.navbar}>
        {layoutProps.navbar && <Navbar />}
        <Stack marginLeft={5}>
          <Stack textAlign={"initial"}>
            {title && <Typography variant="h4">{title}</Typography>}
          </Stack>
          {layoutProps.card ? (
            <ContentCard>
              {layoutProps.back && (
                <Button
                  type="button"
                  variant="outlined"
                  startIcon={<ArrowBack />}
                  text="Volver"
                  onClick={() => navigate(-1)}
                />
              )}
              <Component {...props} />
            </ContentCard>
          ) : (
            <Component {...props} />
          )}
        </Stack>
      </Layout>
    </LayoutContextProvider>
  );
};
