const constants = {
  headers: [
    {
      accessor: "name",
      label: "Nombre",
    },
    {
      accessor: "category",
      label: "Categoría",
    },
    {
      accessor: "city",
      label: "Ciudad",
    },
    {
      accessor: "address",
      label: "Dirección",
    },
    {
      accessor: "rating",
      label: "Rating",
    },
    {
      accessor: "phone",
      label: "Teléfono",
    },
    {
      accessor: "actions",
      label: "Acciones",
    },
  ],
  editButton: "Editar",
  noStoresYet: "Aun no hay negocios",
  noResults: "No hay resultados",
};

export default constants;
