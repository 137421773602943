import React from "react";
import { CustomSwitch, FormControl } from "./form-switch.styles";
import { SwitchProps as MUISwitchProps, Typography } from "@mui/material";
import { Label } from "../label/label.component";
import { useController } from "react-hook-form";
// import { useController, UseControllerProps } from "react-hook-form";

type SwitchProps = MUISwitchProps & {
  label?: string;
  errorMessage?: string;
};

const FormSwitch = React.forwardRef<MUISwitchProps, SwitchProps>(
  ({ label, errorMessage, ...props }, ref) => {
    return (
      <FormControl fullWidth>
        {label && <Label>{label}</Label>}
        <CustomSwitch inputRef={ref} {...props} />
        {errorMessage && (
          <Typography ml={1} color="error" variant="caption">
            {errorMessage}
          </Typography>
        )}
      </FormControl>
    );
  }
);

export default FormSwitch;

export function ControlledFormSwitch(
  props: any
  // props: UseControllerProps<TextFieldProps>
) {
  const {
    field,
    fieldState: { error },
  } = useController(props);

  return <FormSwitch {...props} checked={field.value} {...field} errorMessage={error?.message} />;
}
