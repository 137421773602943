import { Chip } from "@mui/material";
import { FC } from "react";
import { getCouponStatus } from "./coupon-status.constants";

interface CouponStatusProps {
  coupon: Coupon;
}

const CouponStatus: FC<CouponStatusProps> = ({ coupon }) => {
  const status = getCouponStatus(coupon);
  return (
    <Chip
      sx={{ width: "fit-content" }}
      size="small"
      label={status.label.toUpperCase()}
      color={status.color}
    />
  );
};

export default CouponStatus;
