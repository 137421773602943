import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

export const TextFieldContainer = styled("div")({});

export const CustomTextField = styled(TextField)`
  && .MuiInputBase-root {
    /* background-color: ${({ theme, value }) => !value && theme.palette.grey[700]}; */
    border-radius: 10px;
  }
`;
