import { Star } from "@mui/icons-material";
import { Stack, Typography, useTheme } from "@mui/material";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router";
import { Button, Table } from "../../../../common/components/ui";
import { TableData } from "../../../../common/components/ui/table/table.component";
import storesTableConstants from "./stores-table.constants";
import { StoreAvatar } from "./stores-table.styles";
import Dot from "@mui/icons-material/FiberManualRecord";

interface StoresTableProps {
  stores: SummaryStore[];
  loading: boolean;
  total: number;
  showInactive: boolean;
}

const StoresTable: FC<StoresTableProps> = ({ stores, loading, total, showInactive }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const getStoresData = useCallback(() => {
    const data: TableData[] = stores.map((store) => ({
      name: (
        <Stack flexDirection={"row"} alignItems="center">
          {showInactive && (
            <Dot
              fontSize={"inherit"}
              sx={{ color: store.active ? theme.palette.success.light : theme.palette.error.main }}
            />
          )}
          {store.logo ? (
            <StoreAvatar src={store.logo.url} />
          ) : (
            <StoreAvatar>{store.name[0]}</StoreAvatar>
          )}
          {store.name}
        </Stack>
      ),
      category: store.category.name,
      city: store.city.name,
      address: store.address,
      phone: store.phoneNumber,
      rating: (
        <Stack flexDirection={"row"} alignContent="center" justifyContent={"center"}>
          <Star color="primary" />
          <Typography>{store.rating.toFixed(1)}</Typography>
        </Stack>
      ),
      actions: (
        <Button
          onClick={() => navigate("/negocios/editar/" + store.id)}
          text={storesTableConstants.editButton}
          variant={"text"}
        />
      ),
    }));
    return data;
  }, [stores, loading, total]);

  return (
    <Table
      loading={loading}
      total={total}
      constants={{
        noDataYet: storesTableConstants.noStoresYet,
        noResults: storesTableConstants.noResults,
      }}
      headers={storesTableConstants.headers}
      data={getStoresData()}
      keyName={"stores"}
    />
  );
};

export default StoresTable;
