import { IconButton as MUIIconButton, Stack, styled, Link as MUILink } from "@mui/material";

export const ButtonsContainer = styled(Stack)`
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  gap: 10px;
`;

export const ImageContainer = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const HelpImage = styled("img")`
  height: 100%;
`;

export const Link = styled(MUILink)`
  cursor: pointer;
`;

export const IconButton = styled(MUIIconButton)`
  position: absolute;
  right: 0;
  background-color: white;
  top: 0;
`;
