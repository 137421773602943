import { Grid, MenuItem, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import useLayout from "../../common/hooks/useLayout";
import { withLayout } from "../../hocs/with-layout";
import { ButtonsContainer } from "./stores-users-form-screen.styles";
import { Button, ControlledFormInput, ControlledFormSelect } from "../../common/components/ui";
import storeUsersFormScreenConstants, {
  formDefaultValues,
} from "./stores-users-form-screen.constants";
import { FormProvider, useForm } from "react-hook-form";
import useSnackbar from "../../common/hooks/useSnackbar";
import NotFoundAnimation from "../not-found/components/not-found-animation.component";
import { StoreUserBody, UserApiService } from "../../api/user.api.service";
import { StoreApiService } from "../../api/store.api.service";

export interface StoreUsersFormStepProps {
  userToEdit?: Store;
}

const StoreUserFormScreen = () => {
  const { userId } = useParams();
  const { setTitle } = useLayout();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const formMethods = useForm({
    defaultValues: formDefaultValues,
  });

  const [userToEdit, setUserToEdit] = useState<User>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [stores, setStores] = useState<SummaryStore[]>([]);
  const [fetchingStores, setFetchingStores] = useState<boolean>(true);

  useEffect(() => {
    StoreApiService.queryStores({
      sortBy: "name",
    }).then((storesRes) => {
      setStores(storesRes.data.results);
      setFetchingStores(false);
    });
  }, []);

  // Fetch user to edit
  useEffect(() => {
    if (userId) {
      setTitle(storeUsersFormScreenConstants.title.edit());
      UserApiService.getUserById(userId)
        .then((userRes) => {
          if (userRes.data.role === "store") {
            setUserToEdit(userRes.data);
          } else {
            showSnackbar(storeUsersFormScreenConstants.errorUserNotStore);
            setNotFound(true);
          }
        })
        .catch((err) => {
          setNotFound(true);
        });
    } else {
      setTitle(storeUsersFormScreenConstants.title.create);
    }
  }, [userId]);

  // Populate form fields with user to edit data after data fetched
  useEffect(() => {
    if (userToEdit && !fetchingStores) {
      formMethods.setValue("name", userToEdit.name);
      formMethods.setValue("email", userToEdit.email);
      formMethods.setValue("storeId", userToEdit.myStore?.id ?? "");
      setTitle(storeUsersFormScreenConstants.title.edit(userToEdit.name));
    }
  }, [userToEdit, fetchingStores]);

  const handleSubmitForm = formMethods.handleSubmit((formData) => {
    const body: StoreUserBody = {
      ...formData,
      storeId: formData.storeId ?? "",
    };

    const isEdit = !!userId;
    const promise = isEdit
      ? UserApiService.updateStoreUser(body, userId)
      : UserApiService.createStoreUser(body);
    promise
      .then(() => {
        showSnackbar(storeUsersFormScreenConstants.success(isEdit), {
          severity: "success",
        });
        navigate("/admin-negocios");
      })
      .catch((err) => {
        showSnackbar(storeUsersFormScreenConstants.error(true), {
          severity: "error",
        });
        // setLoading(false);
      });
  });

  const deleteUser = () => {
    if (userId) {
      UserApiService.deleteUser(userId).then(() => {
        navigate("/admin-negocios");
      });
    }
  };

  if (notFound) {
    return <NotFoundAnimation />;
  }

  return (
    <Stack>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitForm}>
          <Grid container mt={3}>
            <Grid item md={6} xs={12}>
              <Grid item xs={12} sm={10} mb={2}>
                <ControlledFormInput
                  name="name"
                  control={formMethods.control}
                  label={storeUsersFormScreenConstants.labels.name}
                  rules={{
                    required: storeUsersFormScreenConstants.rules.required,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={10} mb={2}>
                <ControlledFormSelect
                  name="storeId"
                  control={formMethods.control}
                  rules={{
                    required: storeUsersFormScreenConstants.rules.required,
                  }}
                  label={storeUsersFormScreenConstants.labels.store}
                >
                  {stores.map((store) => (
                    <MenuItem key={"store-" + store.id} value={store.id}>
                      {store.name}
                    </MenuItem>
                  ))}
                </ControlledFormSelect>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid item xs={12} sm={10} mb={2}>
                <ControlledFormInput
                  name="email"
                  control={formMethods.control}
                  label={storeUsersFormScreenConstants.labels.email}
                  rules={{
                    required: storeUsersFormScreenConstants.rules.required,
                    pattern: {
                      value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                      message: storeUsersFormScreenConstants.rules.email,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={10} mb={2}>
                <ControlledFormInput
                  name="password"
                  control={formMethods.control}
                  label={storeUsersFormScreenConstants.labels.password}
                  type="password"
                  rules={{
                    required: storeUsersFormScreenConstants.rules.required,
                    minLength: {
                      value: 8,
                      message: storeUsersFormScreenConstants.rules.min8Length,
                    },
                    pattern: {
                      value: /\D\d|\d\D/g,
                      message: "Debe contener al menos 1 caracter y 1 número ",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <ButtonsContainer>
            {userId && (
              <Button
                text={storeUsersFormScreenConstants.buttons.delete}
                variant="contained"
                type={"button"}
                color="error"
                onClick={deleteUser}
              />
            )}
            <Button
              text={storeUsersFormScreenConstants.buttons.save(!!userToEdit)}
              variant="contained"
              type={"submit"}
            />
          </ButtonsContainer>
        </form>
      </FormProvider>
    </Stack>
  );
};

export default withLayout(StoreUserFormScreen, {
  title: storeUsersFormScreenConstants.title.default,
  card: true,
  navbar: true,
  back: true,
});
