import { DateTime } from "luxon";

const isExpired = (coupon: Coupon) => {
  return DateTime.fromISO(coupon.expires) < DateTime.now();
};

const voucherStatus = {
  redeemed: "redeemed",
  expired: "expired",
  active: "active",
};

export const couponUtils = {
  isExpired,
  voucherStatus,
};
