import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import feedbacksTableConstants from "../feedbacks-table/feedbacks-table.constants";

interface Props {
  showModal: boolean;
  onClose: () => void;
  action: () => void;
  text: string;
}

const ConfirmDialog = ({ text, showModal, onClose, action }: Props) => {
  return (
    <Dialog onClose={onClose} open={showModal}>
      <DialogContent>
        <Typography>{text}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{feedbacksTableConstants.buttons.cancel}</Button>
        <Button onClick={action}>{feedbacksTableConstants.buttons.accept}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
