import { Avatar, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import useSnackbar from "../../common/hooks/useSnackbar";
import { withLayout } from "../../hocs/with-layout";
import NotFoundAnimation from "../not-found/components/not-found-animation.component";
import ReviewItem from "./components/review-item.component";
import reviewsDetailsScreenConstants from "./reviews-details-screen.constants";
import Slider from "react-slick";
import { ButtonsContainer } from "./reviews-details-screen.styles";
import { Button } from "../../common/components/ui";
import { dateUtils } from "../../common/utils/date.utils";
import { ReviewsApiService } from "../../api/reviews.api.service";
import { Check, Close, Star, StarOutline } from "@mui/icons-material";

const ReviewsDetailsScreen = () => {
  const { reviewId } = useParams();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [review, setReview] = useState<Nullable<UserReview>>(null);
  const [notFound, setNotFound] = useState<boolean>(false);

  useEffect(() => {
    if (reviewId) {
      ReviewsApiService.getReviewByIdToManage(reviewId)
        .then((reviewRes) => {
          setReview(reviewRes.data);
        })
        .catch((err: any) => {
          setNotFound(true);
          showSnackbar(reviewsDetailsScreenConstants.error, {
            severity: "error",
          });
        });
    } else {
      setNotFound(true);
    }
  }, [reviewId]);

  const rejectReview = () => {
    if (reviewId) {
      ReviewsApiService.rejectReview(reviewId)
        .then(() => {
          showSnackbar(reviewsDetailsScreenConstants.successRejecting);
          navigate("/opiniones");
        })
        .catch(() => {
          showSnackbar(reviewsDetailsScreenConstants.errorRejecting, {
            severity: "error",
          });
        });
    }
  };

  const approveReview = () => {
    if (reviewId) {
      ReviewsApiService.approveReview(reviewId)
        .then(() => {
          showSnackbar(reviewsDetailsScreenConstants.successApproving);
          navigate("/opiniones");
        })
        .catch(() => {
          showSnackbar(reviewsDetailsScreenConstants.errorApproving, {
            severity: "error",
          });
        });
    }
  };

  if (notFound) {
    return <NotFoundAnimation />;
  }

  return review ? (
    <Grid container textAlign={"initial"}>
      <Grid item md={6}>
        <ReviewItem
          label={reviewsDetailsScreenConstants.labels.store}
          description={review.store.name}
        />
        <ReviewItem
          label={reviewsDetailsScreenConstants.labels.rating}
          description={
            <Stack flexDirection={"row"} alignContent="center">
              {[0, 1, 2, 3, 4].map((ind) => {
                const filled = ind + 1 <= review.rate;
                return filled ? <Star color="primary" /> : <StarOutline />;
              })}
            </Stack>
          }
        />
        <ReviewItem
          label={reviewsDetailsScreenConstants.labels.date}
          description={dateUtils.formatDate(review.createdAt)}
        />
        <ReviewItem
          label={reviewsDetailsScreenConstants.labels.comments}
          description={review.comment ?? "-"}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <ReviewItem label={reviewsDetailsScreenConstants.labels.user}>
          <Stack mt={1} direction={"row"} alignItems="center">
            <Avatar src={review.user.picture?.url} />
            <Typography ml={1}>{review.user.name}</Typography>
          </Stack>
        </ReviewItem>

        {review.photos.length > 0 && (
          <ReviewItem label={reviewsDetailsScreenConstants.labels.pictures}>
            <Slider dots={true} infinite={false} speed={500} slidesToShow={1} slidesToScroll={1}>
              {review.photos.map((pic, val) => (
                <img alt={"image-" + val} key={"review-image-" + val} src={pic.url} />
              ))}
            </Slider>
          </ReviewItem>
        )}
      </Grid>
      <Grid item xs={12}>
        <ButtonsContainer>
          <Button
            startIcon={<Check />}
            color="success"
            text={reviewsDetailsScreenConstants.approve}
            variant="contained"
            onClick={approveReview}
          />
          <Button
            startIcon={<Close />}
            color="error"
            text={reviewsDetailsScreenConstants.reject}
            variant="contained"
            onClick={rejectReview}
          />
        </ButtonsContainer>
      </Grid>
    </Grid>
  ) : (
    <CircularProgress />
  );
};

export default withLayout(ReviewsDetailsScreen, {
  card: true,
  navbar: true,
  title: reviewsDetailsScreenConstants.title,
  back: true,
});
