import { Grid, MenuItem, Modal, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import useLayout from "../../common/hooks/useLayout";
import { withLayout } from "../../hocs/with-layout";
import {
  ButtonsContainer,
  HelpImage,
  ImageContainer,
  Link,
  IconButton,
} from "./coupons-form-screen.styles";
import {
  Button,
  ControlledFormInput,
  ControlledFormSelect,
  ControlledFormSwitch,
} from "../../common/components/ui";
import couponsFormScreenConstants, { formDefaultValues } from "./coupons-form-screen.constants";
import { FormProvider, useForm } from "react-hook-form";
import useSnackbar from "../../common/hooks/useSnackbar";
import NotFoundAnimation from "../not-found/components/not-found-animation.component";
import { StoreApiService } from "../../api/store.api.service";
import { CouponBody, CouponApiService } from "../../api/coupon.api.service";
import { DateTime } from "luxon";
import helpImage from "./help.png";
import CloseIcon from "@mui/icons-material/Close";

const CouponsFormScreen = () => {
  const { couponId } = useParams();
  const { setTitle } = useLayout();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const formMethods = useForm({
    defaultValues: formDefaultValues,
  });

  const [couponToEdit, setCouponToEdit] = useState<Coupon>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [stores, setStores] = useState<SummaryStore[]>([]);
  const [fetchingStores, setFetchingStores] = useState<boolean>(true);
  const [showHelp, setShowHelp] = useState<boolean>(false);

  const hasLimit = formMethods.watch("hasLimit");

  useEffect(() => {
    StoreApiService.queryStores({
      sortBy: "name",
    }).then((storesRes) => {
      setStores(storesRes.data.results);
      setFetchingStores(false);
    });
  }, []);

  // Fetch coupon to edit
  useEffect(() => {
    if (couponId) {
      setTitle(couponsFormScreenConstants.title.edit());
      CouponApiService.getCouponDetails(couponId)
        .then((couponRes) => {
          setCouponToEdit(couponRes.data);
        })
        .catch((err) => {
          setNotFound(true);
        });
    } else {
      setTitle(couponsFormScreenConstants.title.create);
    }
  }, [couponId]);

  // Populate form fields with coupon to edit data after data fetched
  useEffect(() => {
    if (couponToEdit && !fetchingStores) {
      formMethods.setValue("description", couponToEdit.description);
      formMethods.setValue("discount", couponToEdit.discount);
      formMethods.setValue(
        "expires",
        DateTime.fromISO(couponToEdit.expires).toFormat("yyyy-MM-dd")
      );
      formMethods.setValue("hasLimit", couponToEdit.limit > 0);
      formMethods.setValue("limit", couponToEdit.limit);
      formMethods.setValue("price", couponToEdit.price);
      formMethods.setValue("storeId", couponToEdit.store._id);
      formMethods.setValue("title", couponToEdit.title);
      formMethods.setValue("active", couponToEdit.active);
      setTitle(couponsFormScreenConstants.title.edit(couponToEdit.title));
    }
  }, [couponToEdit, fetchingStores]);

  const handleSubmitForm = formMethods.handleSubmit((formData) => {
    if (formData.storeId) {
      let body: CouponBody = {
        title: formData.title,
        price: formData.price,
        description: formData.description,
        discount: formData.discount,
        limit: formData.hasLimit ? formData.limit : 0,
        expires: formData.expires ? DateTime.fromISO(formData.expires).toISO() : "",
      };

      const isEdit = !!couponId;
      const promise = isEdit
        ? CouponApiService.updateCoupon(couponId, { ...body, active: formData.active })
        : CouponApiService.createCoupon({ ...body, storeId: formData.storeId });
      promise
        .then(() => {
          showSnackbar(couponsFormScreenConstants.success(isEdit), {
            severity: "success",
          });
          navigate("/cupones");
        })
        .catch(() => {
          showSnackbar(couponsFormScreenConstants.error(true), {
            severity: "error",
          });
        });
    }
  });

  // const deactivateCoupon = () => {
  //   if (couponId) {
  //     CouponApiService.deactivateCoupon(couponId).then(() => {
  //       navigate("/cupones");
  //     });
  //   }
  // };

  if (notFound) {
    return <NotFoundAnimation />;
  }

  return (
    <Stack>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitForm}>
          <Grid container mt={3}>
            <Grid item md={6} xs={12}>
              <Grid item xs={12} sm={10} mb={2}>
                <ControlledFormInput
                  name="title"
                  control={formMethods.control}
                  label={couponsFormScreenConstants.labels.title}
                  rules={{
                    required: couponsFormScreenConstants.rules.required,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={10} mb={2}>
                <ControlledFormSelect
                  name="storeId"
                  disabled={!!couponId}
                  control={formMethods.control}
                  rules={{
                    required: couponsFormScreenConstants.rules.required,
                  }}
                  label={couponsFormScreenConstants.labels.store}
                >
                  {stores.map((store) => (
                    <MenuItem key={"store-" + store.id} value={store.id}>
                      {store.name}
                    </MenuItem>
                  ))}
                </ControlledFormSelect>
              </Grid>
              <Grid item xs={12} sm={10} mb={2}>
                <ControlledFormInput
                  name="description"
                  multiline
                  control={formMethods.control}
                  label={couponsFormScreenConstants.labels.description}
                />
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid item xs={12} sm={10} mb={2}>
                <ControlledFormInput
                  name="discount"
                  control={formMethods.control}
                  label={couponsFormScreenConstants.labels.discount}
                  rules={{
                    required: couponsFormScreenConstants.rules.required,
                  }}
                />
              </Grid>
              <Grid container mb={2}>
                <Grid item xs={12} sm={5}>
                  <ControlledFormSwitch
                    name="hasLimit"
                    disabled={!!couponToEdit}
                    control={formMethods.control}
                    label={couponsFormScreenConstants.labels.hasLimit}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <ControlledFormInput
                    name="limit"
                    disabled={!hasLimit || !!couponToEdit}
                    type="number"
                    control={formMethods.control}
                    label={couponsFormScreenConstants.labels.limit}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={10}>
                <Grid container justifyContent={"space-between"}>
                  <Grid item xs={12} sm={5} mb={2}>
                    <ControlledFormInput
                      type="number"
                      name="price"
                      label={couponsFormScreenConstants.labels.price}
                      control={formMethods.control}
                      rules={{
                        required: couponsFormScreenConstants.rules.required,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} mb={2}>
                    <ControlledFormInput
                      type="date"
                      name="expires"
                      label={couponsFormScreenConstants.labels.expires}
                      control={formMethods.control}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {couponId && (
                <Grid item xs={12} sm={10}>
                  <ControlledFormSwitch
                    name="active"
                    control={formMethods.control}
                    label={couponsFormScreenConstants.labels.active}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Link onClick={() => setShowHelp(true)}>{couponsFormScreenConstants.help}</Link>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">{couponsFormScreenConstants.note}</Typography>
            </Grid>
          </Grid>
          <ButtonsContainer>
            <Button
              text={couponsFormScreenConstants.buttons.save(!!couponToEdit)}
              variant="contained"
              type={"submit"}
            />
          </ButtonsContainer>
        </form>
      </FormProvider>
      <Modal open={showHelp} onClose={() => setShowHelp(false)}>
        <ImageContainer>
          <IconButton onClick={() => setShowHelp(false)} aria-label="close" size="large">
            <CloseIcon fontSize="inherit" />
          </IconButton>
          <HelpImage src={helpImage} />
        </ImageContainer>
      </Modal>
    </Stack>
  );
};

export default withLayout(CouponsFormScreen, {
  title: couponsFormScreenConstants.title.default,
  card: true,
  navbar: true,
  back: true,
});
