const images = {
  error: require('./question.png'),
  home: require('./selfie.png'),
  win: require('./winner.png'),
  lose: require('./mind-blown.png'),
  login: require('./semillas.webp'),
  logoCeliapp: require('./logo-celimapp.svg')
};

export default images;
