import React from "react";
import { CustomFormInput, FormControl } from "./form-input.styles";
import { TextFieldProps as MUITextFieldProps, Typography } from "@mui/material";
import { Label } from "../label/label.component";
import { useController } from "react-hook-form";
// import { useController, UseControllerProps } from "react-hook-form";

type TextFieldProps = MUITextFieldProps & {
  label?: string;
  errorMessage?: string;
};

const FormInput = React.forwardRef<MUITextFieldProps, TextFieldProps>(
  ({ label, errorMessage, ...props }, ref) => {
    return (
      <FormControl fullWidth>
        {label && <Label>{label}</Label>}
        <CustomFormInput
          inputRef={ref}
          size="small"
          {...props}
          error={!!errorMessage}
          label={undefined}
          rows={props.multiline ? 4 : 1}
        />
        {errorMessage && (
          <Typography ml={1} color="error" variant="caption">
            {errorMessage}
          </Typography>
        )}
      </FormControl>
    );
  }
);

export default FormInput;

export function ControlledFormInput(
  props: any
  // props: UseControllerProps<TextFieldProps>
) {
  const {
    field,
    fieldState: { error },
  } = useController(props);

  return <FormInput {...props} {...field} errorMessage={error?.message} />;
}
