import { Stack, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router";
import { Button, Table } from "../../../../common/components/ui";
import { TableData } from "../../../../common/components/ui/table/table.component";
import { dateUtils } from "../../../../common/utils/date.utils";
import { StoreAvatar } from "../../../stores/components/stores-table/stores-table.styles";
import reviewsTableConstants from "./reviews-table.constants";
import { ReviewAvatar } from "./reviews-table.styles";
import { Star } from "@mui/icons-material";

interface ReviewsTableProps {
  reviews: UserReview[];
  loading: boolean;
}

const ReviewsTable: FC<ReviewsTableProps> = ({ reviews, loading }) => {
  const navigate = useNavigate();

  const getReviewsData = useCallback(() => {
    const data: TableData[] = reviews.map((review) => ({
      name: (
        <Stack flexDirection={"row"} alignItems="center">
          {review.user.picture ? (
            <ReviewAvatar src={review.user.picture.url} />
          ) : (
            <ReviewAvatar>{review.user.name[0]}</ReviewAvatar>
          )}
          {review.user.name}
        </Stack>
      ),
      store: (
        <Stack flexDirection={"row"} alignItems="center">
          {review.store.logo ? (
            <StoreAvatar src={review.store.logo.url} />
          ) : (
            <StoreAvatar>{review.store.name[0]}</StoreAvatar>
          )}
          {review.store.name}
        </Stack>
      ),
      rating: (
        <Stack flexDirection={"row"} alignContent="center" justifyContent={"center"}>
          <Star color="primary" />
          <Typography>{review.rate.toFixed(1)}</Typography>
        </Stack>
      ),
      date: dateUtils.formatDate(review.createdAt),
      actions: (
        <Button
          onClick={() => navigate("/opiniones/ver/" + review.id)}
          text={reviewsTableConstants.viewButton}
          variant={"text"}
        />
      ),
    }));
    return data;
  }, [reviews, loading]);

  return (
    <Stack>
      <Table
        headers={reviewsTableConstants.headers}
        data={getReviewsData()}
        loading={loading}
        keyName={"reviews"}
        constants={{
          noDataYet: reviewsTableConstants.noReviewsYet,
          noResults: reviewsTableConstants.noReviewsYet,
        }}
      />
    </Stack>
  );
};

export default ReviewsTable;
