import { Search } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Switch,
} from "@mui/material";
import { FC, useState } from "react";
import { Button, TextField } from "../../../../common/components/ui";
import {
  CustomFormSelect,
  FormControl,
} from "../../../../common/components/ui/form-select/form-select.styles";
import FeaturesSelect from "../../../stores-form/components/features-select/features-select.component";
import storesFiltersContants from "./stores-filters.constants";

export type StoresFiltersSelected = {
  city: string;
  features: string[];
  search: string;
  inactive: boolean;
};

interface StoresFiltersProps {
  filters: StoresFiltersSelected;
  setFilters: (filters: StoresFiltersSelected) => void;
  cities: SummaryCity[];
}

const constants = {
  searchPlaceholder: "Buscar negocio...",
  showInactive: "Mostrar inactivos",
};

const StoresFilters: FC<StoresFiltersProps> = ({ filters, setFilters, cities }) => {
  const [showFeatures, setShowFeatures] = useState<boolean>(false);

  const handleChangeCity = (event: SelectChangeEvent<unknown>) => {
    setFilters({
      ...filters,
      city: event.target.value as string,
    });
  };

  const toggleShowFeatures = () => {
    setShowFeatures(!showFeatures);
  };

  const updateProp = (key: string, value: any) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          sx={{ maxWidth: 400 }}
          size="small"
          value={filters.search}
          setValue={(val: string) => updateProp("search", val)}
          placeholder={constants.searchPlaceholder}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                onChange={(ev) => updateProp("inactive", ev.target.checked)}
                checked={filters.inactive}
              />
            }
            label={constants.showInactive}
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12} sm={5}>
        <FormControl fullWidth>
          <InputLabel id="filter-city">{storesFiltersContants.labels.city}</InputLabel>
          <CustomFormSelect
            value={filters.city}
            labelId="filter-city"
            label={storesFiltersContants.labels.city}
            onChange={handleChangeCity}
            size={"small"}
          >
            <MenuItem value="">{storesFiltersContants.selects.anyCity}</MenuItem>
            {cities.map((city) => (
              <MenuItem value={city.cityId}>{city.name}</MenuItem>
            ))}
          </CustomFormSelect>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          variant="text"
          onClick={toggleShowFeatures}
          text={storesFiltersContants.buttons.features(filters.features.length)}
        />
      </Grid>
      <Dialog onClose={toggleShowFeatures} open={showFeatures}>
        <DialogContent>
          <FeaturesSelect
            value={filters.features}
            setValue={(values) => updateProp("features", values)}
            label={storesFiltersContants.labels.features}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => updateProp("features", [])}
            text={storesFiltersContants.buttons.clean}
          />
          <Button onClick={toggleShowFeatures} text={storesFiltersContants.buttons.confirm} />
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default StoresFilters;
