import { TextField, FormControl as MUIFormControl } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomFormInput = styled(TextField)`
  && .MuiInputBase-root {
    border-radius: 10px;
  }
`;

export const FormControl = styled(MUIFormControl)`
  text-align: initial;
`;
