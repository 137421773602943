const constants = {
  headers: [
    {
      accessor: "store",
      label: "Negocio",
    },
    {
      accessor: "status",
      label: "Estado",
    },
    {
      accessor: "expires",
      label: "Vencimiento",
    },
    {
      accessor: "title",
      label: "Título",
    },
    {
      accessor: "available",
      label: "Canjeados",
    },
    {
      accessor: "actions",
      label: "Acciones",
    },
  ],
  editButton: "Editar",
  viewButton: "Ver",
  noCouponsYet: "Aun no hay cupones",
  noResults: "No hay resultados",
  unlimited: "Sin límite",
};

export default constants;
