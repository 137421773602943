import { Box, styled } from "@mui/material";

export const DrawerContainer = styled(Box)`
  height: 100vh;
  background-color: #fff;
  width: 25%;
  max-width: 300px;
  flex-shrink: 0;
  box-sizing: "border-box";
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 60px;
    overflow: hidden;
    width: ${({ theme }) => theme.spacing(10)};
    max-width: 50px;
    padding: 0;
    margin: 0;
  }
`;

export const Logo = styled("img")`
  margin-top: 20px;
  ${(props) => props.theme.breakpoints.down("md")} {
    margin-top: 50px;
  }
  margin-bottom: 20px;
`;

export const Divider = styled("div")`
  padding: 1;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
  margin: 0 10%;
`;
