import { Grid, Stack } from "@mui/material";
import { FC, useState } from "react";
import { FeedbackApiService } from "../../../../api/feedback.api.service";
import { Table } from "../../../../common/components/ui";
import useSnackbar from "../../../../common/hooks/useSnackbar";
import { dateUtils } from "../../../../common/utils/date.utils";
import ConfirmDialog from "../confirm-dialog/confirm-dialog.component";
import feedbacksTableConstants from "./feedbacks-table.constants";
import { AcceptButton, CancelButton, FeedbackAvatar } from "./feedbacks-table.styles";

interface FeedbacksTableProps {
  total: number;
  feedbacks: Feedback[];
  loading: boolean;
  fetchFeedbacks: () => void;
}

const FeedbacksTable: FC<FeedbacksTableProps> = ({ total, feedbacks, loading, fetchFeedbacks }) => {
  const { showSnackbar } = useSnackbar();
  const [showAccept, setShowAccept] = useState<Nullable<string>>(null);
  const [showReject, setShowReject] = useState<Nullable<string>>(null);

  const acceptFeedback = () => {
    if (showAccept) {
      FeedbackApiService.approveFeedback(showAccept)
        .then(() => {
          showSnackbar(feedbacksTableConstants.acceptedSuccess, {
            severity: "success",
          });
          fetchFeedbacks();
        })
        .catch(() => {
          showSnackbar(feedbacksTableConstants.acceptedError, {
            severity: "error",
          });
        })
        .finally(() => {
          setShowAccept(null);
        });
    }
  };

  const rejectFeedback = () => {
    if (showReject) {
      FeedbackApiService.rejectFeedback(showReject)
        .then(() => {
          showSnackbar(feedbacksTableConstants.rejectedSuccess, {
            severity: "success",
          });
          fetchFeedbacks();
        })
        .catch(() => {
          showSnackbar(feedbacksTableConstants.rejectedError, {
            severity: "error",
          });
        })
        .finally(() => {
          setShowReject(null);
        });
    }
  };

  return (
    <>
      <Table
        data={feedbacks.map((feedback) => ({
          user: (
            <Stack flexDirection={"row"} alignItems="center">
              {feedback.user.picture?.url ? (
                <FeedbackAvatar src={feedback.user.picture?.url} />
              ) : (
                <FeedbackAvatar>{feedback.user.name[0] ?? feedback.user.name[0]}</FeedbackAvatar>
              )}
              {feedback.user.name}
            </Stack>
          ),
          subject: feedback.subject,
          message: feedback.message,
          date: dateUtils.formatDate(feedback.createdAt),
          actions: (
            <Grid container>
              <Grid xs={6}>
                <CancelButton
                  onClick={() => {
                    setShowReject(feedback.id);
                  }}
                />
              </Grid>
              <Grid xs={6}>
                <AcceptButton
                  onClick={() => {
                    setShowAccept(feedback.id);
                  }}
                />
              </Grid>
            </Grid>
          ),
        }))}
        headers={feedbacksTableConstants.headers}
        loading={loading}
        total={total}
        constants={{
          noDataYet: feedbacksTableConstants.noFeedbacksYet,
          noResults: feedbacksTableConstants.noFeedbacksYet,
        }}
        keyName={"feedbacks"}
      />
      {showAccept && (
        <ConfirmDialog
          text={feedbacksTableConstants.dialogs.accept}
          showModal={!!showAccept}
          onClose={() => {
            setShowAccept(null);
          }}
          action={acceptFeedback}
        />
      )}
      {showReject && (
        <ConfirmDialog
          text={feedbacksTableConstants.dialogs.reject}
          showModal={!!showReject}
          onClose={() => {
            setShowReject(null);
          }}
          action={rejectFeedback}
        />
      )}
    </>
  );
};

export default FeedbacksTable;
