import { Stack, Typography } from "@mui/material";
import { FC } from "react";

interface CouponItemProps {
  label: string;
  description?: string;
}

const CouponItem: FC<CouponItemProps> = ({ label, description, ...props }) => {
  return (
    <Stack m={1}>
      <Typography fontWeight={"bold"} variant="subtitle2">
        {label}
      </Typography>
      {description && (
        <Typography ml={1} variant="subtitle1">
          {description}
        </Typography>
      )}
      {props.children}
    </Stack>
  );
};

export default CouponItem;
