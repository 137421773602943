import { Chip } from "@mui/material";
import { FC } from "react";
import { getVoucherStatus } from "./voucher-status.constants";

interface VoucherStatusProps {
  voucher: Voucher;
}

const VoucherStatus: FC<VoucherStatusProps> = ({ voucher }) => {
  const status = getVoucherStatus(voucher);
  return (
    <Chip
      sx={{ width: "fit-content" }}
      size="small"
      label={status.label.toUpperCase()}
      color={status.color}
    />
  );
};

export default VoucherStatus;
