import { LinearProgress, Stack } from "@mui/material";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router";
import { Button, Table } from "../../../../common/components/ui";
import { TableData } from "../../../../common/components/ui/table/table.component";
import couponsTableConstants from "./coupons-table.constants";
import { StoreAvatar } from "./couopns-table.styles";
import CouponStatus from "../../../../common/components/coupon-status/coupon-status.component";
import { dateUtils } from "../../../../common/utils/date.utils";

interface CouponsTableProps {
  coupons: Coupon[];
  loading: boolean;
  total: number;
}

const CouponsTable: FC<CouponsTableProps> = ({ coupons, loading, total }) => {
  const navigate = useNavigate();

  const getCouponsData = useCallback(() => {
    const data: TableData[] = coupons.map((coupon) => ({
      store: (
        <Stack flexDirection={"row"} alignItems="center">
          {coupon.store.logo ? (
            <StoreAvatar src={coupon.store.logo.url} />
          ) : (
            <StoreAvatar>{coupon.store.name[0]}</StoreAvatar>
          )}
          {coupon.store.name}
        </Stack>
      ),
      status: <CouponStatus coupon={coupon} />,
      expires: dateUtils.formatDate(coupon.expires),
      title: coupon.title,
      available:
        coupon.limit > 0 ? (
          <LinearProgress variant="determinate" value={(coupon.available / coupon.limit) * 100} />
        ) : (
          couponsTableConstants.unlimited
        ),
      actions: (
        <Stack>
          <Button
            onClick={() => navigate("/cupones/editar/" + coupon.id)}
            text={couponsTableConstants.editButton}
            variant={"text"}
          />
          <Button
            onClick={() => navigate("/cupones/ver/" + coupon.id)}
            text={couponsTableConstants.viewButton}
            variant={"text"}
          />
        </Stack>
      ),
    }));
    return data;
  }, [coupons, loading, total]);

  return (
    <Table
      loading={loading}
      total={total}
      constants={{
        noDataYet: couponsTableConstants.noCouponsYet,
        noResults: couponsTableConstants.noResults,
      }}
      headers={couponsTableConstants.headers}
      data={getCouponsData()}
      keyName={"coupons"}
    />
  );
};

export default CouponsTable;
