import { Stack, styled, Typography } from "@mui/material";

export const HeaderText = styled(Typography)`
  flex-grow: 1;
  padding: 10px;
  text-align: center;
  cursor: pointer;
`;

export const NavbarContainer = styled(Stack)`
  margin: ${({ theme }) => theme.spacing(2)};
`;
