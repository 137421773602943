import { couponUtils } from "../../common/utils/coupon.utils";
import { dateUtils } from "../../common/utils/date.utils";

const constants = {
  title: "Detalle de cupón",
  editCouponButton: "Editar cupón",
  error: "Error al obtener cupón",
  labels: {
    store: "Negocio",
    title: "Título",
    discount: "Descuento",
    description: "Descripción",
    active: "Activo",
    status: "Estado",
    expires: "Vencimiento",
    date: "Creado",
    availability: "Disponibilidad",
    limit: "Límite",
    available: "Disponible",
    redeemed: "Canjeados",
    consumed: "Usados",
    vouchers: "Vouchers",
  },
  noLimit: "Sin límite",
  noVouchers: "No hay cupones canjeados",
  getVoucherStatus: (status: VoucherStatus) => {
    switch (status) {
      case couponUtils.voucherStatus.redeemed: {
        return "Usado";
      }
      case couponUtils.voucherStatus.expired: {
        return "Vencido";
      }
      case couponUtils.voucherStatus.active: {
        return "Activo";
      }
    }
  },
  getVoucherDate: (voucher: Voucher) => {
    switch (voucher.status) {
      case couponUtils.voucherStatus.redeemed: {
        return "Usado: " + dateUtils.formatDate(voucher.redeemedAt);
      }
      case couponUtils.voucherStatus.expired: {
        return "Venció: " + dateUtils.formatDate(voucher.expires);
      }
      case couponUtils.voucherStatus.active: {
        return "Canjeado: " + dateUtils.formatDate(voucher.createdAt);
      }
    }
  },
};

export default constants;
