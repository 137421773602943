import { FormControl as MUIFormControl, Select } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomFormSelect = styled(Select)`
  border-radius: 10px;
`;

export const FormControl = styled(MUIFormControl)`
  text-align: initial;
`;
