import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RewardsApiService } from "../../api/rewards.api.service";
import { Button } from "../../common/components/ui";
import useSnackbar from "../../common/hooks/useSnackbar";
import { withLayout } from "../../hocs/with-layout";
import RewardsTable from "./components/rewards-table/rewards-table.component";
import rewardsConstants from "./rewards-screen.constants";
import { RewardsContainer } from "./rewards-screen.styles";

const RewardsScreen = () => {
  const { showSnackbar } = useSnackbar();

  const [rewards, setRewards] = useState<Reward[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    RewardsApiService.getRewards().then((res) => {
      setRewards(
        res.data.map((rew) => ({
          name: rew.name,
          key: rew.key,
          points: rew.points,
        }))
      );
      setLoading(false);
    });
  }, []);

  const formMethods = useForm({
    defaultValues: rewardsConstants.form,
  });

  const onSubmit = formMethods.handleSubmit(async (formData) => {
    setLoading(true);
    await RewardsApiService.updateRewards(formData.rewards)
      .then(() => {
        showSnackbar(rewardsConstants.success, {
          severity: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        showSnackbar(rewardsConstants.error, {
          severity: "error",
        });
      })
      .finally(() => setLoading(false));
  });

  useEffect(() => {
    formMethods.setValue("rewards", rewards);
  }, [rewards]);

  return (
    <RewardsContainer>
      <FormProvider {...formMethods}>
        <form onSubmit={onSubmit}>
          <Stack mt={5}>
            <RewardsTable loading={loading} total={10} />
          </Stack>
          <Button type="submit" variant="contained" text={rewardsConstants.save} />
        </form>
      </FormProvider>
    </RewardsContainer>
  );
};

export default withLayout(RewardsScreen, {
  title: rewardsConstants.title,
  card: true,
  navbar: true,
});
