import { FC } from "react";
import { CustomButton } from "./button.styles";
import { ButtonProps as RNButtonProps } from "@mui/material";

type ButtonProps = RNButtonProps & {
  text: string;
};

const Button: FC<ButtonProps> = ({ text, ...props }) => {
  return (
    // <ButtonContainer>
    <CustomButton {...props}>
      {text}
      {props.children}
    </CustomButton>
    // </ButtonContainer>
  );
};

export default Button;
