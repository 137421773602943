import { del, get, post, put } from "./api.service";

const endpoint = "/users";

export interface StoreUserBody {
  email: string;
  name: string;
  storeId: string;
  password: string;
}

const getDetails = () => get<User>({ endpoint: endpoint + "/details", auth: true });

const getStoreUsers = () => get<User[]>({ endpoint: endpoint + "/store", auth: true });

const createStoreUser = (body: StoreUserBody) =>
  post({ endpoint: endpoint + "/store", auth: true, body });

const updateStoreUser = (body: StoreUserBody, userId: string) =>
  put({ endpoint: endpoint + "/store/" + userId, auth: true, body });

const getUserById = (userId: string) =>
  get<User>({ endpoint: endpoint + "/" + userId, auth: true });

const deleteUser = (userId: string) =>
  del({ endpoint: endpoint + "/" + userId, auth: true, body: {} });

export const UserApiService = {
  getDetails,
  getStoreUsers,
  createStoreUser,
  updateStoreUser,
  getUserById,
  deleteUser,
};
