const constants = {
  headers: [
    {
      accessor: "name",
      label: "Usuario",
    },
    {
      accessor: "store",
      label: "Negocio",
    },
    {
      accessor: "rating",
      label: "Puntuación",
    },
    {
      accessor: "date",
      label: "Fecha",
    },
    {
      accessor: "actions",
      label: "Acciones",
    },
  ],
  viewButton: "Ver",
  noReviewsYet: "Aun no hay opiniones",
};

export default constants;
