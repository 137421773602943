import { Checkbox, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useController } from "react-hook-form";
import { TextField } from "../../../../common/components/ui";
import { Label } from "../../../../common/components/ui/label/label.component";
import { SocialMediaInput } from "./social-media-select.constants";
import { FormControlLabel, FormGroup, SocialMediaContainer } from "./social-media-select.styles";

interface SocialMediaSelectProps {
  values: SocialMediaInput;
  setValues: (features: SocialMediaInput) => void;
  label: string;
  errorMessage?: string;
}

const SocialMediaSelect: FC<SocialMediaSelectProps> = ({
  values,
  setValues,
  label,
  errorMessage,
}) => {
  const updateValue = (socialMedia: SocialMediaName, prop: string, value: any) => {
    setValues({
      ...values,
      [socialMedia]: {
        ...values[socialMedia],
        [prop]: value,
      },
    });
  };

  return (
    <FormGroup>
      <Label>{label}</Label>
      {Object.entries(values).map(([name, socialMediaData]) => {
        const castedName = name as SocialMediaName;
        return (
          <Stack key={name}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(ev) => updateValue(castedName, "checked", ev.target.checked)}
                  checked={socialMediaData.checked}
                />
              }
              label={
                <SocialMediaContainer>
                  {socialMediaData.icon}
                  {socialMediaData.label}
                </SocialMediaContainer>
              }
            />
            {socialMediaData.checked && values && (
              <TextField
                size={"small"}
                placeholder={socialMediaData.label + " URL"}
                value={socialMediaData.url ?? ""}
                setValue={(text: string) => updateValue(castedName, "url", text)}
              />
            )}
          </Stack>
        );
      })}
      {errorMessage && (
        <Typography ml={1} color="error" variant="caption">
          {errorMessage}
        </Typography>
      )}
    </FormGroup>
  );
};

export default SocialMediaSelect;

export function ControlledSocialMediaSelect(props: any) {
  const {
    field,
    fieldState: { error },
  } = useController({
    ...props,
    rules: {
      validate: {
        required: (v) => {
          const wrong = Object.values(v).find((social: any) => social.checked && !social.url);
          return !wrong || "URL requerida para redes seleccionadas";
        },
      },
    },
  });

  return (
    <SocialMediaSelect
      {...props}
      errorMessage={error?.message}
      values={field.value}
      setValues={field.onChange}
    />
  );
}
