import {
  ListItem as MUIListItem,
  ListItemProps as MUIListItemProps,
  ListItemText as MUIListItemText,
  ListItemIcon as MUIListItemIcon,
  styled,
} from "@mui/material";

interface ListItemProps extends MUIListItemProps {
  active?: boolean;
}

export const ListItem = styled(MUIListItem, {
  shouldForwardProp: (prop) => prop !== "active",
})<ListItemProps>`
  font-weight: bold;
  border-left: 5px solid
    ${({ theme, active }) => (active ? theme.palette.primary.main : "transparent")};
  color: ${({ theme, active }) =>
    active ? theme.palette.primary.main : theme.palette.secondary.main};
`;

export const ListItemText = styled(MUIListItemText)`
  font-weight: bold;
  ${(props) => props.theme.breakpoints.down("md")} {
    display: none;
  }
`;

export const ListItemIcon = styled(MUIListItemIcon)`
  ${(props) => props.theme.breakpoints.down("md")} {
    justify-content: center;
    width: auto;
    max-width: 10px;
    min-width: 15px;
  }
`;
