const constants = {
  headers: [
    {
      accessor: "user",
      label: "Usuario",
    },
    {
      accessor: "subject",
      label: "Asunto",
    },
    {
      accessor: "message",
      label: "Mensaje",
    },
    {
      accessor: "date",
      label: "Fecha",
    },
    {
      accessor: "actions",
      label: "Acciones",
    },
  ],
  noFeedbacksYet: "Aun no hay sugerencias",
  buttons: {
    accept: "Confirmar",
    cancel: "Cancelar",
  },
  dialogs: {
    accept: "Si aceptas esta sugerencia se le asignaran los puntos correspondientes al usuario",
    reject: "Si rechazas esta sugerencia, la misma se eliminará y el usuario no recibirá puntos",
  },
  acceptedSuccess: "Sugerencia de cambio aceptada correctamente",
  acceptedError: "Error al aceptar sugerencia",
  rejectedSuccess: "Sugerencia de cambio rechazada",
  rejectedError: "Error al rechazar sugerencia",
};

export default constants;
