import { withLayout } from "../../hocs/with-layout";
import notFoundScreenConstants from "./not-found-screen.constants";
import NotFoundAnimation from "./components/not-found-animation.component";

const NotFoundScreen = () => {
  return <NotFoundAnimation />;
};

export default withLayout(NotFoundScreen, {
  title: notFoundScreenConstants.title,
  card: true,
  navbar: true,
});
