const constants = {
  headers: [
    {
      accessor: "name",
      label: "Nombre",
    },
    {
      accessor: "category",
      label: "Categoría",
    },
    {
      accessor: "city",
      label: "Ciudad",
    },
    {
      accessor: "address",
      label: "Dirección",
    },
    {
      accessor: "date",
      label: "Fecha",
    },
    {
      accessor: "actions",
      label: "Acciones",
    },
  ],
  viewButton: "Ver",
  noRecommendationsYet: "Aun no hay recomendaciones",
};

export default constants;
