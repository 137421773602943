import { Stack, StackProps, styled } from "@mui/material";

interface LayoutProps extends StackProps {
  hasNavbar?: boolean;
}

const Layout = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "hasNavbar",
})<LayoutProps>`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  margin-left: ${({ theme }) => theme.spacing(5)};
  margin-top: ${({ hasNavbar, theme }) => theme.spacing(hasNavbar ? 3 : 6)};
  flex-grow: 1;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-left: ${({ theme }) => theme.spacing(2)};
  }
`;

export default Layout;
