const constants = {
  title: {
    edit: (coupon?: string) => `Editar ${coupon ?? "cupón"}`,
    create: "Nuevo cupón",
    default: "Cupón",
  },
  errorFetchingCoupon: "Error al buscar cupón",
  error: (isEdit: boolean) => `Error al ${isEdit ? "editar" : "crear"} cupón`,
  success: (isEdit: boolean) => `Cupón ${isEdit ? "editado" : "creado"} correctamente !`,
  buttons: {
    delete: "Eliminar",
    goBack: "Volver",
    save: (isEdit: boolean) => (isEdit ? "Guardar" : "Crear"),
    next: "Siguiente",
  },
  labels: {
    title: "Título *",
    store: "Negocio *",
    discount: "Descuento *",
    hasLimit: "Tiene límite? *",
    limit: "Total disponible",
    price: "Precio en puntos *",
    description: "Descripción",
    expires: "Vencimiento",
    active: "Cupón activo",
  },
  rules: {
    required: "Este campo es requerido",
  },
  help: "Click aquí para ver ejemplo",
  note: "Atención: recuerde que los componentes ya comprados por los usuarios NO serán modificados.",
};

export default constants;

type CouponFormInput = {
  title: string;
  storeId: Nullable<string>;
  description: Nullable<string>;
  discount: string;
  hasLimit: boolean;
  limit: number;
  expires: Nullable<string>;
  price: number;
  active: boolean;
};

export const formDefaultValues: CouponFormInput = {
  title: "",
  description: "",
  discount: "",
  storeId: "",
  hasLimit: false,
  limit: 0,
  expires: null,
  price: 100,
  active: true,
};
