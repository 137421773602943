import storageKeys from "../common/constants/storage-keys.constants";
import adminTokenUtils from "../common/utils/admin-token.utils";
import Axios from "axios";
import { UploadOptions, UploadResponse } from "imagekit-javascript/dist/src/interfaces";

const endpoint = process.env.REACT_APP_BASE_URL + "/upload";

const env = process.env.REACT_APP_NODE_ENV;

const imageKitEnvs = {
  publicKey: process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY ?? "",
  urlEndpoint: process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT ?? "",
  authenticationEndpoint: process.env.REACT_APP_BASE_URL + "/upload/signature",
};

export interface UploadImagesResult {
  urls: string[];
}

type UploadImagesEntities = "reviews" | "stores";

const uploadImages = async (files: File[], entity: UploadImagesEntities) => {
  let formData = new FormData();

  files.forEach((file) => {
    formData.append("images", file);
  });

  const token = localStorage.getItem(storageKeys.userToken);
  const adminToken = adminTokenUtils.generateAdminToken();
  const fullEndpoint = `${endpoint}/images/${entity}`;

  return Axios.post<UploadImagesResult>(fullEndpoint, formData, {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization": "Bearer" + token,
    },
    params: { adminToken },
  });
};

interface UploadFileSignature {
  token: string;
  expire: number;
  signature: string;
}

const getSignatureData = async () => {
  const adminToken = await adminTokenUtils.generateAdminToken();
  return Axios.post<UploadFileSignature>(
    imageKitEnvs.authenticationEndpoint,
    {},
    {
      params: { adminToken },
    }
  );
};

const getImageKitFolder = (path?: string) => {
  let folder = "/celimapp";

  if (env === "development") {
    folder += "-dev";
  }

  if (!path || path.length === 0) {
    return folder;
  }

  if (path[0] !== "/") {
    folder += "/";
  }

  return folder + path;
};

const uploadImageV2 = async (options: UploadOptions) => {
  const { data: signatureData } = await getSignatureData();

  const uploadOptions = {
    ...options,
    ...signatureData,
    folder: getImageKitFolder(options.folder),
  };

  var formData = new FormData();
  let key: keyof typeof uploadOptions;
  for (key in uploadOptions) {
    if (key) {
      if (key === "file" && typeof uploadOptions.file != "string") {
        formData.append("file", uploadOptions.file, String(uploadOptions.fileName));
      } else if (key === "tags" && Array.isArray(uploadOptions.tags)) {
        formData.append("tags", uploadOptions.tags.join(","));
      } else if (key === "responseFields" && Array.isArray(uploadOptions.responseFields)) {
        formData.append("responseFields", uploadOptions.responseFields.join(","));
      } else if (key === "extensions" && Array.isArray(uploadOptions.extensions)) {
        formData.append("extensions", JSON.stringify(uploadOptions.extensions));
      } else if (
        key === "customMetadata" &&
        typeof uploadOptions.customMetadata === "object" &&
        !Array.isArray(uploadOptions.customMetadata) &&
        uploadOptions.customMetadata !== null
      ) {
        formData.append("customMetadata", JSON.stringify(uploadOptions.customMetadata));
      } else {
        formData.append(key, String(uploadOptions[key]));
      }
    }
  }

  formData.append("publicKey", imageKitEnvs.publicKey);

  const { data: imageRes } = await Axios.post<UploadResponse>(
    "https://upload.imagekit.io/api/v1/files/upload",
    formData,
    {
      headers: {
        "content-type": "multipart/form-data",
      },
    }
  );

  const image: Image = {
    fileId: imageRes.fileId,
    name: imageRes.name,
    size: imageRes.size,
    filePath: imageRes.filePath,
    thumbnailUrl: imageRes.thumbnailUrl,
    url: imageRes.url,
    height: imageRes.height,
    width: imageRes.width,
    fileType: imageRes.fileType,
  };

  return image;
};

export const UploadApiService = {
  uploadImages,
  uploadImageV2,
};
