import { FC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ControlledFormInput, Table } from "../../../../common/components/ui";
import rewardsTableConstants from "./rewards-table.constants";

interface RewardsTableProps {
  total: number;
  loading: boolean;
}

const RewardsTable: FC<RewardsTableProps> = ({ total, loading }) => {
  const { control, register } = useFormContext();
  const { fields } = useFieldArray<{ rewards: Reward[] }>({
    name: "rewards",
  });

  return (
    <Table
      data={fields.map((reward, index) => ({
        name: reward.name,
        points: (
          <ControlledFormInput
            id={reward.id}
            control={control}
            type="number"
            min="0"
            {...register(`rewards.${index}.points`)}
          />
        ),
      }))}
      headers={rewardsTableConstants.headers}
      loading={loading}
      total={total}
      constants={{
        noDataYet: rewardsTableConstants.noRewardsYet,
        noResults: rewardsTableConstants.noRewardsYet,
      }}
      keyName={"rewards"}
    />
  );
};

export default RewardsTable;
