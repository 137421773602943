import {
  Avatar,
  CircularProgress,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import useSnackbar from "../../common/hooks/useSnackbar";
import { withLayout } from "../../hocs/with-layout";
import NotFoundAnimation from "../not-found/components/not-found-animation.component";
import CouponItem from "./components/coupon-item.component";
import couponsDetailsScreenConstants from "./coupons-details-screen.constants";
import { ButtonsContainer } from "./coupons-details-screen.styles";
import { Button } from "../../common/components/ui";
import { CouponApiService } from "../../api/coupon.api.service";
import { dateUtils } from "../../common/utils/date.utils";
import CouponStatus from "../../common/components/coupon-status/coupon-status.component";
import VoucherStatus from "../../common/components/voucher-status/voucher-status.component";
import { couponUtils } from "../../common/utils/coupon.utils";

const CouponsDetailsScreen = () => {
  const { couponId } = useParams();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [coupon, setCoupon] = useState<Nullable<Coupon>>(null);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [vouchersRedeemed, setVouchersRedeemed] = useState<number>(0);

  useEffect(() => {
    if (couponId) {
      CouponApiService.getCouponDetails(couponId)
        .then((couponRes) => {
          setCoupon(couponRes.data);
          if (couponRes.data.vouchers) {
            setVouchersRedeemed(
              couponRes.data.vouchers.filter((v) => v.status === couponUtils.voucherStatus.redeemed)
                .length
            );
          }
        })
        .catch((err: any) => {
          if (err.response.status === 404) {
            setNotFound(true);
          } else {
            showSnackbar(couponsDetailsScreenConstants.error);
          }
        });
    }
  }, [couponId]);

  if (notFound) {
    return <NotFoundAnimation />;
  }

  return coupon ? (
    <Grid container textAlign={"initial"}>
      <Grid item md={6}>
        <CouponItem label={couponsDetailsScreenConstants.labels.store}>
          <Stack mt={1} direction={"row"} alignItems="center">
            <Avatar src={coupon.store.logo?.url} />
            <Typography ml={1}>{coupon.store.name}</Typography>
          </Stack>
        </CouponItem>
        <CouponItem label={couponsDetailsScreenConstants.labels.title} description={coupon.title} />
        <CouponItem
          label={couponsDetailsScreenConstants.labels.discount}
          description={coupon.discount}
        />
        <CouponItem
          label={couponsDetailsScreenConstants.labels.description}
          description={coupon.description ?? undefined}
        />
        <CouponItem
          label={couponsDetailsScreenConstants.labels.date}
          description={dateUtils.formatDate(coupon.createdAt)}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CouponItem
          label={couponsDetailsScreenConstants.labels.active}
          description={coupon.active ? "Sí" : "No"}
        />
        <CouponItem label={couponsDetailsScreenConstants.labels.status}>
          <CouponStatus coupon={coupon} />
        </CouponItem>
        <CouponItem
          label={couponsDetailsScreenConstants.labels.expires}
          description={dateUtils.formatDate(coupon.expires)}
        />
        <CouponItem label={couponsDetailsScreenConstants.labels.availability}>
          {coupon.limit > 0 ? (
            <LinearProgress
              sx={{
                marginTop: 1,
                height: 9,
                width: "60%",
              }}
              variant="buffer"
              value={(coupon.available / coupon.limit) * 100}
              valueBuffer={((coupon.limit - vouchersRedeemed) / coupon.limit) * 100}
            />
          ) : (
            couponsDetailsScreenConstants.noLimit
          )}
          <List dense={true}>
            <ListItem>
              <ListItemText
                primary={`${couponsDetailsScreenConstants.labels.limit}: ${coupon.limit}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`${couponsDetailsScreenConstants.labels.available}: ${coupon.available}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`${couponsDetailsScreenConstants.labels.redeemed}: ${
                  coupon.limit - coupon.available
                }`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`${couponsDetailsScreenConstants.labels.consumed}: ${vouchersRedeemed}`}
              />
            </ListItem>
          </List>
        </CouponItem>
      </Grid>
      <Grid item xs={12}>
        <ButtonsContainer>
          <Button
            text={couponsDetailsScreenConstants.editCouponButton}
            variant="contained"
            onClick={() => navigate("/cupones/editar/" + couponId)}
          />
        </ButtonsContainer>
      </Grid>
      <Grid item xs={12}>
        <CouponItem label={couponsDetailsScreenConstants.labels.vouchers}>
          <List dense={false} sx={{ width: "fit-content" }}>
            {coupon.vouchers && coupon.vouchers.length > 0 ? (
              coupon.vouchers.map((voucher, index) => (
                <ListItem key={"voucher-" + voucher.id} sx={{ gap: 2 }}>
                  <ListItemText
                    primary={`${index + 1} - ${voucher.user.name}`}
                    secondary={couponsDetailsScreenConstants.getVoucherDate(voucher)}
                  />
                  <VoucherStatus voucher={voucher} />
                </ListItem>
              ))
            ) : (
              <Typography variant="overline">{couponsDetailsScreenConstants.noVouchers}</Typography>
            )}
          </List>
        </CouponItem>
      </Grid>
    </Grid>
  ) : (
    <CircularProgress />
  );
};

export default withLayout(CouponsDetailsScreen, {
  card: true,
  navbar: true,
  title: couponsDetailsScreenConstants.title,
  back: true,
});
