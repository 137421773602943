import { Stack, styled } from "@mui/material";

export const FeatureContainer = styled(Stack)`
  align-items: center;
  flex-direction: row;
  gap: 7px;
`;

export const ButtonsContainer = styled(Stack)`
  justify-content: flex-end;
  width: 100%;
  flex-direction: row;
  gap: 10px;
`;
