import { Typography } from "@mui/material";
import { FC, ReactNode, useEffect, useState } from "react";
import CustomTableUI from "./table.styles";

interface TableConstants {
  noDataYet: string;
  noResults: string;
}

export interface TableHeader {
  accessor: string;
  label: string;
}

export interface TableData {
  [accesor: string]: ReactNode;
}

interface CustomTableProps {
  headers: TableHeader[];
  loading: boolean;
  total?: number;
  data: TableData[];
  constants?: Partial<TableConstants>;
  keyName?: string;
}

const defaultConstants: TableConstants = {
  noDataYet: "Aun no hay datos",
  noResults: "No hubo resultados",
};

const CustomTable: FC<CustomTableProps> = ({
  headers,
  total,
  loading,
  data,
  constants,
  keyName = Math.random().toString(),
}) => {
  const [tableConstants, setTableConstants] = useState(defaultConstants);

  useEffect(() => {
    const allConstants = { ...defaultConstants };
    Object.assign(allConstants, constants);
    setTableConstants(allConstants);
  }, [constants]);

  return (
    <CustomTableUI.TableContainer>
      <CustomTableUI.Table>
        <CustomTableUI.TableHead>
          <CustomTableUI.TableHeaderRow>
            {headers.map((header, index) => (
              <CustomTableUI.HeaderTableCell
                key={"header-" + header.accessor}
                align={index === 0 ? "left" : "center"}
              >
                {header.label}
              </CustomTableUI.HeaderTableCell>
            ))}
          </CustomTableUI.TableHeaderRow>
        </CustomTableUI.TableHead>
        <CustomTableUI.TableBody>
          {loading ? (
            [0, 1, 2, 3, 4].map((v) => (
              <CustomTableUI.SkeletonRow size={headers.length} key={"skeleton-row-" + v} />
            ))
          ) : data.length === 0 ? (
            <CustomTableUI.TableRow>
              <CustomTableUI.TableCell>
                <Typography mt={2} variant="button">
                  {total === 0 ? tableConstants.noDataYet : tableConstants.noResults}
                </Typography>
              </CustomTableUI.TableCell>
            </CustomTableUI.TableRow>
          ) : (
            data.map((item, ind1) => (
              <CustomTableUI.TableRow key={"table-row-" + keyName + "-" + ind1}>
                {headers.map((header, ind2) => (
                  <CustomTableUI.TableCell
                    key={"table-cell-" + keyName + "-" + ind2}
                    component={ind2 === 0 ? "th" : undefined}
                    scope={ind2 === 0 ? "row" : undefined}
                    align={ind2 === 0 ? "left" : "center"}
                  >
                    {item[header.accessor]}
                  </CustomTableUI.TableCell>
                ))}
              </CustomTableUI.TableRow>
            ))
          )}
        </CustomTableUI.TableBody>
      </CustomTableUI.Table>
    </CustomTableUI.TableContainer>
  );
};

export default CustomTable;
