import { useState } from "react";
import { Grid, MenuItem } from "@mui/material";
import { ButtonsContainer } from "../notification-form-screen.styles";
import {
  Button,
  ControlledFileImageUpload,
  ControlledFormInput,
  ControlledFormSelect,
} from "../../../common/components/ui";
import notificationFormScreenConstants from "../notification-form-screen.constants";
import { useFormContext } from "react-hook-form";
import { GroupedCities } from "../../../api/location.api.service";

interface Props {
  stores: SummaryStore[];
  formMethods: any;
  cities: GroupedCities[];
  disabled: boolean;
}
const NotificationForm = ({ stores, formMethods, cities, disabled }: Props) => {
  const [selectedProvinceCities, setSelectedProvinceCities] = useState<City[]>([]);
  const { control } = useFormContext();

  return (
    <>
      <Grid container mt={3}>
        <Grid item md={6} xs={12}>
          <Grid item xs={12} sm={10} mb={2}>
            <ControlledFormInput
              name="title"
              disabled={disabled}
              control={formMethods.control}
              label={notificationFormScreenConstants.labels.title}
              rules={{
                required: notificationFormScreenConstants.rules.required,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={10} mb={2}>
            <ControlledFormSelect
              name="storeId"
              disabled={disabled}
              control={formMethods.control}
              label={notificationFormScreenConstants.labels.store}
            >
              <MenuItem value="">{notificationFormScreenConstants.emptyField.store}</MenuItem>
              {stores.map((store) => (
                <MenuItem key={"store-" + store.id} value={store.id}>
                  {store.name}
                </MenuItem>
              ))}
            </ControlledFormSelect>
          </Grid>
          <Grid item xs={12} sm={10} mb={2}>
            <ControlledFormInput
              name="description"
              disabled={disabled}
              multiline
              control={formMethods.control}
              rules={{
                required: notificationFormScreenConstants.rules.required,
              }}
              label={notificationFormScreenConstants.labels.description}
            />
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid item xs={12} sm={10} mb={2}>
            <ControlledFormSelect
              name="provinceId"
              disabled={disabled}
              control={control}
              onChange={(prov: any) =>
                setSelectedProvinceCities(
                  cities.find((city) => city.province.id === prov?.target?.value)?.cities || []
                )
              }
              label={notificationFormScreenConstants.labels.province}
            >
              <MenuItem value="">{notificationFormScreenConstants.emptyField.province}</MenuItem>
              {cities.map((city) => (
                <MenuItem key={"province-" + city.province.id} value={city.province.id}>
                  {city.province.name}
                </MenuItem>
              ))}
            </ControlledFormSelect>
          </Grid>
          <Grid item xs={12} sm={10} mb={2}>
            <ControlledFormSelect
              name="cityId"
              disabled={disabled}
              control={control}
              label={notificationFormScreenConstants.labels.city}
            >
              <MenuItem value="">{notificationFormScreenConstants.emptyField.city}</MenuItem>
              {selectedProvinceCities.map((city) => (
                <MenuItem key={"city-" + city.id} value={city.id}>
                  {city.name}
                </MenuItem>
              ))}
            </ControlledFormSelect>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <ControlledFileImageUpload
            name="image"
            disabled={disabled}
            label={notificationFormScreenConstants.labels.logo}
            control={control}
            circle
            url={null}
            height={150}
          />
        </Grid>
      </Grid>
      <ButtonsContainer>
        <Button
          text={notificationFormScreenConstants.buttons.save()}
          disabled={disabled}
          variant="contained"
          type={"submit"}
        />
      </ButtonsContainer>
    </>
  );
};

export default NotificationForm;
