import { Step as MUIStep, StepLabel, Stepper as MUIStepper } from "@mui/material";
import { FC } from "react";
import { QontoConnector, QontoStepIcon } from "./stepper.styles";

interface StepperProps {
  steps: Step[];
  activeStep: number;
}

const Stepper: FC<StepperProps> = ({ steps, activeStep }) => {
  return (
    <MUIStepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
      {steps.map(({ label }) => (
        <MUIStep key={label}>
          <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
        </MUIStep>
      ))}
    </MUIStepper>
  );
};

export default Stepper;
