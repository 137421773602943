import { GoogleGeocoderResult } from "../../api/location.api.service";

const getGoogleResultAddressFormat = (value: GoogleGeocoderResult) => {
  const street = value.address_components.find((comp) => comp.types.includes("route"))?.short_name;
  if (street) {
    const number = value.address_components.find((comp) =>
      comp.types.includes("street_number")
    )?.short_name;
    return `${street} ${number ?? ""}`;
  } else {
    const intersection = value.address_components.find((comp) =>
      comp.types.includes("intersection")
    )?.short_name;
    return intersection;
  }
};

const locationUtils = {
  getGoogleResultAddressFormat,
};

export default locationUtils;
