import homeScreenConstants from "./home-screen.constants";
import { Box, Stack, Typography } from "@mui/material";
import { HomeBoxContainer } from "./home-screen.styles";
import Lottie from "lottie-react";
import useAuth from "../../common/hooks/useAuth";
import { withLayout } from "../../hocs/with-layout";
import homeAnimation from "./components/home-animation.json";
import { Button } from "../../common/components/ui";

const HomeScreen = () => {
  const { logout } = useAuth();
  return (
    <HomeBoxContainer>
      <Typography variant="h4" component="div">
        {homeScreenConstants.welcomeMessage}
      </Typography>
      <Box>
        <Lottie animationData={homeAnimation} style={{ height: 400 }} />
      </Box>
      <Stack spacing={3} alignItems="center">
        <Button onClick={logout} text={homeScreenConstants.logout} variant={"contained"} />
      </Stack>
    </HomeBoxContainer>
  );
};

export default withLayout(HomeScreen, {
  title: homeScreenConstants.title,
  card: true,
  navbar: true,
});
