const emptyRewards: Reward[] = [];

const constants = {
  title: "Recompensas",
  form: {
    rewards: emptyRewards,
  },
  success: "Recompensas actualizadas correctamente",
  error: "Error al actualizar recompensas",
  save: "Guardar cambios",
};

export default constants;
