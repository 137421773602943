import LoginScreenConstants from "./login-screen.constants";
import { Box, CircularProgress, Grid } from "@mui/material";
import { Navigate } from "react-router-dom";
import logo from "../../common/assets/images/logo-celimapp.svg";
import images from "../../common/assets/images";
import { TextFieldContainer, HomeImage, ButtonContainer, Logo } from "./login-screen.styles";
import { Button, TextField } from "../../common/components/ui";
import { useState } from "react";
import { AuthApiService } from "../../api/auth.api.service";
import useSnackbar from "../../common/hooks/useSnackbar";
import storageKeys from "../../common/constants/storage-keys.constants";
import useAuth from "../../common/hooks/useAuth";

const LoginScreen = () => {
  const { showSnackbar } = useSnackbar();
  const { user, loadingUser, storeUser } = useAuth();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const onLogin = () => {
    if (email && password) {
      setLoading(true);
      AuthApiService.login(email, password)
        .then((authRes) => {
          const { token, user } = authRes.data;
          localStorage.setItem(storageKeys.userToken, token);
          storeUser(user);
        })
        .catch((err: any) => {
          showSnackbar(err.response.data.message, {
            severity: "error",
          });
        })
        .finally(() => setLoading(false));
    }
  };

  if (loadingUser) {
    return <CircularProgress />;
  }

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <HomeImage src={`${images.login}`} />
      </Grid>
      <Grid item xs={12} md={6} display="flex">
        <Grid container alignItems="center" justifyContent="center" flexDirection="column">
          <Logo width={350} src={logo} />
          <Box>
            <TextFieldContainer>
              <TextField
                value={email}
                setValue={setEmail}
                placeholder={LoginScreenConstants.user}
              />
            </TextFieldContainer>
            <TextFieldContainer>
              <TextField
                value={password}
                setValue={setPassword}
                type="password"
                placeholder={LoginScreenConstants.password}
              />
            </TextFieldContainer>
            <ButtonContainer>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  disabled={!password || !email}
                  text={LoginScreenConstants.login}
                  onClick={onLogin}
                  variant="contained"
                />
              )}
            </ButtonContainer>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginScreen;
