import { get } from "./api.service";

const endpoint = "/locations";

export interface GroupedCities {
  province: {
    id: string;
    name: string;
  };
  cities: City[];
}

type LatLng = {
  lat: number;
  lng: number;
};

type GeocoderLocationType = "APPROXIMATE" | "GEOMETRIC_CENTER" | "RANGE_INTERPOLATED" | "ROOFTOP";

export interface GoogleGeocoderResult {
  types: string[];
  formatted_address: string;
  address_components: {
    short_name: string;
    long_name: string;
    postcode_localities: string[];
    types: string[];
  }[];
  partial_match: boolean;
  place_id: string;
  postcode_localities: string[];
  geometry: {
    location: LatLng;
    location_type: GeocoderLocationType;
    viewport: LatLng;
    bounds: LatLng;
  };
}

export const getCitiesGroup = () =>
  get<GroupedCities[]>({
    endpoint: endpoint + "/group",
    auth: false,
    axiosConfig: {
      params: { sortBy: "province,name" },
    },
  });

const searchLocation = (search: string) =>
  get<GoogleGeocoderResult[]>({
    endpoint: endpoint + "/address",
    auth: true,
    axiosConfig: {
      params: {
        search,
      },
    },
  });

const findLocation = (coordinates: LatLng) =>
  get<City>({
    endpoint: endpoint + "/find-city",
    auth: true,
    axiosConfig: {
      params: coordinates,
    },
  });

export const LocationApiService = {
  getCitiesGroup,
  searchLocation,
  findLocation,
};
