const constants = {
  title: {
    create: "Nueva notificación",
  },
  error: `Error al crear notificacion`,
  success: `Notificacion enviada correctamente!`,
  buttons: {
    save: () => "Crear",
  },
  labels: {
    title: "Título *",
    store: "Negocio",
    province: "Provincia *",
    city: "Ciudad *",
    description: "Descripción *",
    logo: "Imagen",
  },
  rules: {
    required: "Este campo es requerido",
  },
  options: {
    store: "Notificar a un negocio",
    user: "Notificar a un usuario",
    city: "Notificar a una ciudad",
  },
  type: {
    store: "STORE",
    user: "USER",
    city: "CITY",
  },
  emptyField: {
    store: "Ningún negocio",
    city: "Ninguna ciudad",
    province: "Ninguna provincia",
  },
};

export default constants;

type NotificationFormInput = {
  title: string;
  storeId: Nullable<string>;
  description: string;
  cityId: string;
  image: Nullable<File>;
};

export const formDefaultValues: NotificationFormInput = {
  title: "",
  description: "",
  storeId: "",
  image: null,
  cityId: "",
};
