import { Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { withLayout } from "../../hocs/with-layout";
import FeedbacksTable from "./components/feedbacks-table/feedbacks-table.component";
import { FeedbacksContainer } from "./feedbacks-screen.styles";
import feedbacksConstants from "./feedbacks-screen.constants";
import { FeedbackApiService } from "../../api/feedback.api.service";

const FeedbacksScreen = () => {
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchFeedbacks = useCallback(() => {
    FeedbackApiService.getFeedbacks().then((feedbackRes) => {
      setFeedbacks(feedbackRes.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    fetchFeedbacks();
  }, [fetchFeedbacks]);

  return (
    <FeedbacksContainer>
      <Stack mt={5}>
        <FeedbacksTable
          fetchFeedbacks={fetchFeedbacks}
          total={feedbacks.length}
          feedbacks={feedbacks}
          loading={loading}
        />
      </Stack>
    </FeedbacksContainer>
  );
};

export default withLayout(FeedbacksScreen, {
  title: feedbacksConstants.title,
  card: true,
  navbar: true,
});
