import { useEffect, useState } from "react";
import { StoresFiltersSelected } from "./stores-filters.component";
import dataUtils from "../../../../common/utils/data.utils";

export const useStoresFilters = (stores: SummaryStore[], filters: StoresFiltersSelected) => {
  const [filteredStores, setFilteredStores] = useState<SummaryStore[]>([]);

  useEffect(() => {
    setFilteredStores(
      stores.filter((store) => {
        let apply = true;

        if (filters.search) {
          apply = store.name.toLowerCase().includes(filters.search.toLowerCase());
          if (!apply) return false;
        }

        if (!filters.inactive) {
          apply = store.active;
          if (!apply) return false;
        }

        if (filters.city) {
          apply = store.city.cityId === filters.city;
          if (!apply) return false;
        }

        if (filters.features.length > 0) {
          apply =
            dataUtils.intersection(filters.features, store.features).length ===
            filters.features.length;
          if (!apply) return false;
        }

        return true;
      })
    );
  }, [filters, stores]);

  return [filteredStores];
};
