import { get, post } from "./api.service";

const endpoint = "/rewards";

const getRewards = () => get<Reward[]>({ endpoint, auth: true });

const updateRewards = (rewards: Reward[]) =>
  post<Reward[]>({ endpoint, body: { rewards }, auth: true });

export const RewardsApiService = {
  getRewards,
  updateRewards,
};
