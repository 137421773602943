import { createContext, Dispatch, SetStateAction } from "react";

interface Actions {
  setTitle: Dispatch<SetStateAction<string | undefined>>;
}

const actions: Actions = {
  setTitle: () => {},
};

interface LayoutState {
  title: string | undefined;
}

const defaultState: LayoutState = {
  title: "",
};

export const LayoutContext = createContext({ ...defaultState, ...actions });

const LayoutContextProvider = ({
  children,
  title,
  setTitle,
}: {
  children: JSX.Element;
  title?: string;
  setTitle: Dispatch<SetStateAction<string | undefined>>;
}) => {
  return <LayoutContext.Provider value={{ title, setTitle }}>{children}</LayoutContext.Provider>;
};

export default LayoutContextProvider;
