import { InputAdornment, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { Button, TextField } from "../../common/components/ui";
import { withLayout } from "../../hocs/with-layout";
import { StoreUsersContainer } from "./store-users-scren.styles";
import storeUsersScreenConstants from "./store-users-screen.constants";
import { Search } from "@mui/icons-material";
import { useNavigate } from "react-router";
import StoreUsersTable from "./components/store-users-table/store-users-table.component";
import { UserApiService } from "../../api/user.api.service";

const StoreUsersScreen = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    UserApiService.getStoreUsers().then((userRes) => {
      setUsers(userRes.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  useEffect(() => {
    if (search) {
      setFilteredUsers(
        users.filter((u) => u.myStore?.name.toLowerCase().includes(search.toLowerCase()))
      );
    } else {
      setFilteredUsers(users);
    }
  }, [search]);

  return (
    <StoreUsersContainer>
      <Stack direction={"row"} justifyContent="space-between">
        <TextField
          sx={{ width: 400 }}
          size="small"
          value={search}
          setValue={setSearch}
          placeholder={storeUsersScreenConstants.searchPlaceholder}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Button
          onClick={() => navigate("/admin-negocios/crear")}
          text={storeUsersScreenConstants.createUser}
          variant="contained"
        />
      </Stack>
      <Stack mt={5}>
        <StoreUsersTable total={users.length} users={filteredUsers} loading={loading} />
      </Stack>
    </StoreUsersContainer>
  );
};

export default withLayout(StoreUsersScreen, {
  card: true,
  navbar: true,
  title: "Administradores de negocios",
});
