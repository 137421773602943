import {
  styled,
  TableHead as MUITableHead,
  Table as MUITable,
  TableBody as MUITableBody,
  TableCell as MUITableCell,
  TableContainer as MUITableContainer,
  TableRow as MUITableRow,
  Avatar,
  Skeleton,
} from "@mui/material";
import { FC } from "react";

const TableHead = styled(MUITableHead)`
  text-transform: uppercase;
`;

const CustomTable = styled(MUITable)``;
const TableBody = styled(MUITableBody)``;
const TableCell = styled(MUITableCell)``;
const HeaderTableCell = styled(MUITableCell)`
  color: #2c3e50a1;
  font-weight: 600;
`;
const TableContainer = styled(MUITableContainer)``;
const TableRow = styled(MUITableRow)`
  :hover {
    background-color: ${({ theme }) => theme.palette.grey[700]};
  }
  &:last-child td,
  &:last-child th {
    border: 0;
  }
`;

const TableHeaderRow = styled(MUITableRow)`
  &:last-child td,
  &:last-child th {
    border: 0;
  }
`;

const TableAvatar = styled(Avatar)`
  height: 24px;
  width: 24px;
  margin-right: 5px;
  object-fit: fill;
`;

interface SkeletonRowProps {
  size?: number;
}

const SkeletonRow: FC<SkeletonRowProps> = ({ size }) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Skeleton variant="circular" width={24} height={24} />
      </TableCell>
      {[...Array(size ?? 5)].map((_, ind) => (
        <TableCell key={"skeleton-cell-" + ind} align="center">
          {<Skeleton />}
        </TableCell>
      ))}
    </TableRow>
  );
};

const TableUI = {
  TableHead,
  Table: CustomTable,
  TableBody,
  TableCell,
  HeaderTableCell,
  TableContainer,
  TableRow,
  TableHeaderRow,
  TableAvatar,
  SkeletonRow,
};

export default TableUI;
