import React from "react";
import { CustomFormSelect, FormControl } from "./form-select.styles";
import { SelectProps as MUISelectProps, Typography } from "@mui/material";
import { Label } from "../label/label.component";
import { useController } from "react-hook-form";

type SelectProps = MUISelectProps & {
  label?: string;
  errorMessage?: string;
};

const FormSelect = React.forwardRef<MUISelectProps, SelectProps>(
  ({ label, errorMessage, ...props }, ref) => {
    return (
      <FormControl error={!!errorMessage} fullWidth>
        {label && <Label>{label}</Label>}
        <CustomFormSelect
          error={!!errorMessage}
          inputRef={ref}
          size="small"
          fullWidth
          {...props}
          label={undefined}
        >
          {props.children}
        </CustomFormSelect>
        {errorMessage && (
          <Typography ml={1} color="error" variant="caption">
            {errorMessage}
          </Typography>
        )}
      </FormControl>
    );
  }
);

export default FormSelect;

export function ControlledFormSelect(props: any) {
  const {
    field,
    fieldState: { error },
  } = useController(props);
  return (
    <FormSelect errorMessage={error?.message} {...field} {...props}>
      {props.children}
    </FormSelect>
  );
}
