import { get, post } from "./api.service";

const endpoint = "/feedback";

const getFeedbacks = () => get<Feedback[]>({ endpoint, auth: true });

const approveFeedback = (feedbackId: string) =>
  post({ endpoint: endpoint + "/" + feedbackId + "/approve", body: {}, auth: true });

const rejectFeedback = (feedbackId: string) =>
  post({ endpoint: endpoint + "/" + feedbackId + "/reject", body: {}, auth: true });

export const FeedbackApiService = {
  getFeedbacks,
  approveFeedback,
  rejectFeedback,
};
