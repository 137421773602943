import {
  styled,
  FormControlLabel as MUIFormControlLabel,
  Stack,
  FormGroup as MUIFormGroup,
} from "@mui/material";

export const Feature = styled("img")`
  height: 36px;
  width: 36px;
`;

export const FormControlLabel = styled(MUIFormControlLabel)`
  margin: 5px 0;
`;

export const SocialMediaContainer = styled(Stack)`
  align-items: center;
  flex-direction: row;
  gap: 7px;
`;

export const FormGroup = styled(MUIFormGroup)`
  text-align: initial;
`;
