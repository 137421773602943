import useAuth from "../../common/hooks/useAuth";
import { Navigate, Outlet } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const ProtectedRoute = () => {
  const { user, loadingUser } = useAuth();

  if (loadingUser) return <CircularProgress sx={{ marginTop: 10 }} />;

  if (!user || user.role !== "admin") return <Navigate to={"login"} />;
  return <Outlet />;
};

export default ProtectedRoute;
