import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    background: {
      default: "#F4F7FE",
    },
    mode: "light",
    primary: {
      main: "#F95C4F",
      light: "#ffe5e2",
      100: "#ffb8b3",
      200: "#fc8b82",
      300: "#fa5e51",
      400: "#f83221",
      500: "#de1907",
      600: "#ae1205",
      dark: "#7d0b03",
      800: "#4c0500",
      900: "#200000",
    },
    secondary: {
      main: "#2C3E50",
      light: "#eaf3fe",
      100: "#ccd9e7",
      200: "#adbfd2",
      300: "#8da6bf",
      400: "#6e8cac",
      500: "#547392",
      600: "#405972",
      dark: "#2d4052",
      800: "#192633",
      900: "#010e16",
    },
    warning: {
      main: "#FFD736",
      light: "#fffadb",
      100: "#fff0af",
      200: "#ffe67e",
      300: "#ffdb4d",
      400: "#ffd11e",
      500: "#e6b807",
      600: "#b38f00",
      dark: "#806600",
      800: "#4d3d00",
      900: "#1d1400",
    },
    grey: {
      "700": "#EDEDF0",
      "500": "#C2C2C2",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Poppins",
      color: "#2C3E50",
    },
    h4: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
        },
        text: {
          borderRadius: 0,
          textTransform: "uppercase",
          color: "#2C3E50",
          fontWeight: 600,
          borderBottom: "2px solid #F95C4F",
          padding: 0,
          margin: 0,
          width: "auto",
        },
      },
    },
  },
});
