import { ListItemButton } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { ListItem, ListItemText, ListItemIcon } from "./drawer-item.styles";

interface DrawerItemProps {
  route: DrawerRoute;
  name: string;
}

const DrawerItem: FC<DrawerItemProps> = ({ name, route }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    if (route.path === "/") setIsActive(location.pathname === "/");
    else setIsActive(location.pathname.includes(route.path));
  }, [location.pathname]);

  return (
    <ListItem disablePadding active={isActive}>
      <ListItemButton sx={{}} onClick={() => navigate(route.path)} selected={isActive}>
        <ListItemIcon color={isActive ? "primary" : "secondary"}>
          {route.icon && route.icon(isActive ? "primary" : "secondary")}
        </ListItemIcon>
        <ListItemText primary={route.name} />
      </ListItemButton>
    </ListItem>
  );
};
export default DrawerItem;
