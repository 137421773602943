import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { StoreApiService } from "../../api/store.api.service";
import { Button } from "../../common/components/ui";
import { withLayout } from "../../hocs/with-layout";
import storesScreenConstants from "./stores-screen.constants";
import { StoresContainer } from "./stores-screen.styles";
import StoresTable from "./components/stores-table/stores-table.component";
import StoresFilters, {
  StoresFiltersSelected,
} from "./components/stores-filters/stores-filters.component";
import { useStoresFilters } from "./components/stores-filters/useStoresFilters";

const StoresScreen = () => {
  const navigate = useNavigate();
  const [stores, setStores] = useState<SummaryStore[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<StoresFiltersSelected>({
    city: "",
    features: [],
    search: "",
    inactive: false,
  });
  const [cities, setCities] = useState<SummaryCity[]>([]);

  const [filteredStores] = useStoresFilters(stores, filters);

  useEffect(() => {
    StoreApiService.queryStores().then((storesRes) => {
      setStores(storesRes.data.results);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const map = new Map<string, SummaryCity>();
    stores.forEach((store) => {
      map.set(store.city.cityId, store.city);
    });
    setCities(Array.from(map.values()));
  }, [stores]);

  return (
    <StoresContainer>
      <Stack direction={"row"} justifyContent="space-between">
        <StoresFilters cities={cities} filters={filters} setFilters={setFilters} />
        <Stack sx={{ minWidth: 138 }}>
          <Button
            onClick={() => navigate("/negocios/crear")}
            text={storesScreenConstants.createStore}
            variant="contained"
          />
        </Stack>
      </Stack>
      <Stack mt={3}>
        <Typography variant="body1">
          {storesScreenConstants.summary(filteredStores.length, stores.length)}
        </Typography>
      </Stack>
      <Stack mt={1}>
        <StoresTable
          showInactive={filters.inactive}
          total={stores.length}
          stores={filteredStores}
          loading={loading}
        />
      </Stack>
    </StoresContainer>
  );
};

export default withLayout(StoresScreen, {
  title: storesScreenConstants.title,
  card: true,
  navbar: true,
});
