const storesFiltersContants = {
  buttons: {
    clean: "Limpiar",
    confirm: "Confirmar",
    features: (count: number) => `Características (${count})`,
  },
  labels: {
    features: "Características",
    city: "Ciudad",
  },
  selects: {
    anyCity: "Cualquier ciudad",
  },
};

export default storesFiltersContants;
