import { Divider, DrawerContainer, Logo } from "./drawer.styles";
import logo from "../../common/assets/images/logo-celimapp.svg";
import { List } from "@mui/material";
import DrawerItem from "./drawer-item.component/drawer-item.component";
import { drawerRoutes } from "./drawer.constants";

const Drawer = () => {
  return (
    <DrawerContainer>
      <Logo width={200} src={logo} />
      <Divider />
      <List>
        {Object.entries(drawerRoutes).map(([key, route]) => (
          <DrawerItem key={"drawer-item-" + key} name={key} route={route} />
        ))}
      </List>
    </DrawerContainer>
  );
};

export default Drawer;
