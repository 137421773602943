import { couponUtils } from "../../utils/coupon.utils";

type MUIColor =
  | "error"
  | "success"
  | "info"
  | "default"
  | "primary"
  | "secondary"
  | "warning"
  | undefined;

export const getCouponStatus = (coupon: Coupon) => {
  let status: {
    color: MUIColor;
    label: string;
  };
  if (coupon.active) {
    if (couponUtils.isExpired(coupon)) {
      status = {
        label: "Vencido",
        color: "error",
      };
    } else {
      status = {
        label: "Activo",
        color: "success",
      };
    }
  } else {
    status = {
      label: "Inactivo",
      color: "default",
    };
  }
  return status;
};

export const getVoucherStatus = (voucher: Voucher) => {
  let status: {
    color: MUIColor;
    label: string;
  } = {
    color: "default",
    label: "",
  };

  switch (voucher.status) {
    case couponUtils.voucherStatus.active: {
      status = {
        color: "success",
        label: "Activo",
      };
      break;
    }
    case couponUtils.voucherStatus.expired: {
      status = {
        color: "error",
        label: "Vencido",
      };
      break;
    }
    case couponUtils.voucherStatus.redeemed: {
      status = {
        color: "info",
        label: "Usado",
      };
      break;
    }
  }

  return status;
};
