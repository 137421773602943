import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { Label, ControlledFileImageUpload } from "../../../../common/components/ui";
import { StoresFormStepProps } from "../../stores-form-screen.component";
import storesFormStepsConstants from "./stores-form-steps.constants";

const StoresFormStepThree: FC<StoresFormStepProps> = ({ storeToEdit }) => {
  const { control } = useFormContext();

  return (
    <Grid container mt={3}>
      <Grid item md={6} xs={12}>
        <Grid item xs={12} sm={10} mb={2}>
          <ControlledFileImageUpload
            name="logo"
            label={storesFormStepsConstants.labels.logo}
            control={control}
            circle
            required={true}
            url={storeToEdit?.logo?.url ?? null}
            height={150}
          />
        </Grid>
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid item xs={12} sm={10} mb={2}>
          <ControlledFileImageUpload
            label={storesFormStepsConstants.labels.cover}
            name="cover"
            control={control}
            required={true}
            height={150}
            url={storeToEdit?.cover?.url ?? null}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} textAlign="initial">
        <Label>{storesFormStepsConstants.labels.images}</Label>
        <Typography>{storesFormStepsConstants.comingSoon}</Typography>
      </Grid>
    </Grid>
  );
};

export default StoresFormStepThree;
