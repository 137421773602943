import Axios, { AxiosRequestConfig } from "axios";
import storageKeys from "../common/constants/storage-keys.constants";

interface RequestProps {
  endpoint: string;
  auth: boolean;
  axiosConfig?: AxiosRequestConfig;
}

interface RequestPropsWithBody extends RequestProps {
  body: any;
}

export const API_URL = process.env.REACT_APP_API_URL;

export async function post<E>({ endpoint, auth, body }: RequestPropsWithBody) {
  const headers = await getHeaders(auth);
  return Axios.post<E>(API_URL + endpoint, body, { headers });
}

export async function get<E>({ endpoint, auth, axiosConfig }: RequestProps) {
  const headers = await getHeaders(auth);
  return Axios.get<E>(API_URL + endpoint, { headers, ...axiosConfig });
}

export async function put({ endpoint, auth, body }: RequestPropsWithBody) {
  const headers = await getHeaders(auth);
  return Axios.put(API_URL + endpoint, body, { headers });
}

export async function patch({ endpoint, auth, body }: RequestPropsWithBody) {
  const headers = await getHeaders(auth);
  return Axios.patch(API_URL + endpoint, body, { headers });
}

export async function del({ endpoint, auth }: RequestPropsWithBody) {
  const headers = await getHeaders(auth);
  return Axios.delete(API_URL + endpoint, { headers });
}

export async function getHeaders(auth: boolean) {
  let headers = {
    "content-type": "application/json",
    "accept": "application/json",
  };
  if (auth) {
    const token = localStorage.getItem(storageKeys.userToken);
    headers = Object.assign({ Authorization: "Bearer " + token }, headers);
  }
  return headers;
}
