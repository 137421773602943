const constants = {
  title: "Opinión",
  error: "Opinión no encontrada",
  errorRejecting: "Error al rechazar opinión",
  errorApproving: "Error al aceptar opinión",
  successRejecting: "Opinión rechazada correctamente",
  successApproving: "Opinión aceptada correctamente",
  labels: {
    user: "Usuario",
    store: "Negocio",
    comments: "Comentarios",
    rating: "Puntuación",
    date: "Fecha de creación",
    pictures: "Imágenes",
  },
  approve: "Aprobar",
  reject: "Rechazar",
};
export default constants;
