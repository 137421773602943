import { Stack } from "@mui/material";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router";
import { Button, Table } from "../../../../common/components/ui";
import { TableData } from "../../../../common/components/ui/table/table.component";
import { dateUtils } from "../../../../common/utils/date.utils";
import recommendationsTableConstants from "./recommendations-table.constants";
import { RecommendationAvatar } from "./recommendations-table.styles";

interface RecommendationsTableProps {
  recommendations: Recommendation[];
  loading: boolean;
}

const RecommendationsTable: FC<RecommendationsTableProps> = ({ recommendations, loading }) => {
  const navigate = useNavigate();

  const getRecommendationsData = useCallback(() => {
    const data: TableData[] = recommendations.map((recommendation) => ({
      name: (
        <Stack flexDirection={"row"} alignItems="center">
          {recommendation.user.picture ? (
            <RecommendationAvatar src={recommendation.user.picture.url} />
          ) : (
            <RecommendationAvatar>{recommendation.name[0]}</RecommendationAvatar>
          )}
          {recommendation.name}
        </Stack>
      ),
      category: recommendation.category.name,
      city: recommendation.city.name,
      address: recommendation.address,
      date: dateUtils.formatDate(recommendation.createdAt),
      actions: (
        <Button
          onClick={() => navigate("/recomendaciones/ver/" + recommendation.id)}
          text={recommendationsTableConstants.viewButton}
          variant={"text"}
        />
      ),
    }));
    return data;
  }, [recommendations, loading]);

  return (
    <Stack>
      <Table
        headers={recommendationsTableConstants.headers}
        data={getRecommendationsData()}
        loading={loading}
        keyName={"recommendations"}
        constants={{
          noDataYet: recommendationsTableConstants.noRecommendationsYet,
          noResults: recommendationsTableConstants.noRecommendationsYet,
        }}
      />
    </Stack>
  );
};

export default RecommendationsTable;
