import { Modal, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { withLayout } from "../../hocs/with-layout";
import { ImageContainer, IconButton } from "./notification-form-screen.styles";
import notificationsFormScreenConstants, {
  formDefaultValues,
} from "./notification-form-screen.constants";
import { FormProvider, useForm } from "react-hook-form";
import useSnackbar from "../../common/hooks/useSnackbar";
import { StoreApiService } from "../../api/store.api.service";
import CloseIcon from "@mui/icons-material/Close";
import { GroupedCities, LocationApiService } from "../../api/location.api.service";
import { NotificationApiService, NotificationBody } from "../../api/notification.api.service";
import NotificationForm from "./forms/notification-form.component";
import { UploadApiService } from "../../api/upload.api.service";
import imageUtils from "../../common/utils/image.utils";

const NotificationsFormScreen = () => {
  const { showSnackbar } = useSnackbar();

  const formMethods = useForm({
    defaultValues: formDefaultValues,
  });

  const [stores, setStores] = useState<SummaryStore[]>([]);
  const [cities, setCities] = useState<GroupedCities[]>([]);
  const [fetchingStores, setFetchingStores] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showHelp, setShowHelp] = useState<boolean>(false);

  useEffect(() => {
    StoreApiService.queryStores({
      sortBy: "name",
    }).then((storesRes) => {
      setStores(storesRes.data.results);
      setFetchingStores(false);
    });
    LocationApiService.getCitiesGroup().then((citiesRes) => {
      setCities(citiesRes.data);
    });
  }, []);

  const handleSubmitForm = formMethods.handleSubmit(async (formData) => {
    if (formData.title) {
      setSubmitting(true);

      const image = formData.image
        ? await UploadApiService.uploadImageV2({
            file: formData.image,
            fileName: "notification." + imageUtils.getFileExtension(formData.image),
            folder: "/notifications",
          })
        : null;

      let body: NotificationBody = {
        title: formData.title,
        description: formData.description,
        cityId: formData.cityId,
        storeId: formData.storeId,
        image,
      };

      NotificationApiService.createNotification(body)
        .then(() => {
          showSnackbar(notificationsFormScreenConstants.success, {
            severity: "success",
          });
          formMethods.reset();
        })
        .catch(() => {
          showSnackbar(notificationsFormScreenConstants.error, {
            severity: "error",
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  });

  return (
    <Stack>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitForm}>
          <NotificationForm
            disabled={fetchingStores || submitting}
            formMethods={formMethods}
            stores={stores}
            cities={cities}
          />
        </form>
      </FormProvider>
      <Modal open={showHelp} onClose={() => setShowHelp(false)}>
        <ImageContainer>
          <IconButton onClick={() => setShowHelp(false)} aria-label="close" size="large">
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </ImageContainer>
      </Modal>
    </Stack>
  );
};

export default withLayout(NotificationsFormScreen, {
  title: notificationsFormScreenConstants.title.create,
  card: true,
  navbar: true,
});
