import { Photo as MUIPhotoIcon } from "@mui/icons-material";
import { TextField, FormControl as MUIFormControl, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomDropzone = styled(TextField)`
  cursor: pointer !important;
  && .MuiInputBase-root {
    border-radius: 10px;
  }
  && .MuiOutlinedInput-input {
    cursor: pointer !important;
  }
`;

export const FormControl = styled(MUIFormControl)`
  text-align: initial;
  width: 100%;
  && .MuiOutlinedInput-input {
    cursor: pointer !important;
  }
`;

export const ImagePlaceholder = styled("div", {
  shouldForwardProp: (prop) => prop !== "height" && prop !== "circle",
})<{ height: number; circle: boolean }>`
  height: ${({ height }) => height ?? 150}px;
  width: ${({ height }) => height ?? 150}px;
  border-radius: ${({ circle, height }) => (circle ? height / 2 + "px" : 0)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  background: ${({ theme }) => theme.palette.grey[200]};
  color: ${({ theme }) => theme.palette.grey[500]};
  justify-content: center !important;
  align-content: center !important;
  align-items: center !important;
  text-align: center;
`;

export const ButtonContainer = styled(Stack)`
  max-width: fit-content;
  flex-direction: row;
  gap: 10px;
`;

export const ImageContainer = styled(Stack)`
  align-items: center;
`;

export const ImagePreview = styled("img", {
  shouldForwardProp: (prop) => prop !== "height" && prop !== "circle",
})<{ height?: number; circle?: boolean }>`
  max-height: ${({ height }) => height ?? 150}px;
  border-radius: ${({ circle, height = 150 }) => (circle ? height / 2 + "px" : 0)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  max-width: 100%;
`;

export const PhotoIcon = styled(MUIPhotoIcon)`
  font-size: 60px;
  height: 100%;
`;
