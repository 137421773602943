import { couponUtils } from "../../utils/coupon.utils";

export const getCouponStatus = (coupon: Coupon) => {
  let status: {
    color:
      | "error"
      | "success"
      | "info"
      | "default"
      | "primary"
      | "secondary"
      | "warning"
      | undefined;
    label: string;
  };
  if (coupon.active) {
    if (couponUtils.isExpired(coupon)) {
      status = {
        label: "Vencido",
        color: "error",
      };
    } else {
      status = {
        label: "Activo",
        color: "success",
      };
    }
  } else {
    status = {
      label: "Inactivo",
      color: "default",
    };
  }
  return status;
};
