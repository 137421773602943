import { Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useController } from "react-hook-form";
import Button from "../button/button.component";
import { Label } from "../label/label.component";
import fileImageUploadConstants from "./file-image-upload.constants";
import {
  FormControl,
  ButtonContainer,
  ImageContainer,
  ImagePlaceholder,
  ImagePreview,
  PhotoIcon,
} from "./file-image-upload.styles";
import Compressor from "compressorjs";

interface FileUploadProps {
  label: string;
  url: Nullable<string>;
  file: Nullable<File | Blob>;
  setFile: (file: Nullable<File | Blob>) => void;
  circle?: boolean;
  height?: number;
  errorMessage?: string;
}

const FileImageUpload: FC<FileUploadProps> = ({
  label,
  url,
  file,
  setFile,
  circle = false,
  height = 150,
  errorMessage,
}) => {
  const [preview, setPreview] = useState<Nullable<string>>(null);

  useEffect(() => {
    if (file) {
      setPreview(URL.createObjectURL(file));
    } else {
      setPreview(url);
    }
    return () => {
      if (preview && file) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [url, file]);

  return (
    <FormControl>
      <Label>{label}</Label>
      {errorMessage && (
        <Typography ml={1} color="error" variant="caption">
          {errorMessage}
        </Typography>
      )}
      <ImageContainer>
        {preview ? (
          <ImagePreview circle={circle} height={height} src={preview} />
        ) : (
          <ImagePlaceholder circle={circle} height={height}>
            <PhotoIcon />
          </ImagePlaceholder>
        )}
        <ButtonContainer>
          <Button
            // @ts-ignore
            component="label"
            variant="contained"
            color="secondary"
            text={fileImageUploadConstants.select}
          >
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={(ev) => {
                if (ev.target.files) {
                  const image = ev.target.files[0];
                  new Compressor(image, {
                    quality: 0.3, // 0.6 can also be used, but its not recommended to go below.
                    success: (compressedResult) => {
                      setFile(compressedResult);
                    },
                  });
                }
              }}
            />
          </Button>
          {file && (
            <Button
              color="secondary"
              onClick={() => setFile(null)}
              variant="outlined"
              text={fileImageUploadConstants.remove}
            />
          )}
        </ButtonContainer>
      </ImageContainer>
    </FormControl>
  );
};
export default FileImageUpload;

export function ControlledFileImageUpload(props: any) {
  const {
    field,
    fieldState: { error },
  } = useController({
    ...props,
    rules: {
      validate: {
        required: (f) => !props.required || !!f || !!props.url || "Imagen requerida",
      },
    },
  });

  return (
    <FileImageUpload
      {...props}
      file={field.value}
      setFile={field.onChange}
      errorMessage={error?.message}
    />
  );
}
