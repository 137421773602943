const getImageUri = (image: string) => {
  let uri =
    image.includes("https://") || image.includes("http://") || image.includes("file://")
      ? image
      : process.env.REACT_APP_BASE_URL + "/" + image;
  return uri;
};

const getFileExtension = (file: File) => {
  return file.name.split(".").pop();
};

const imageUtils = {
  getImageUri,
  getFileExtension,
};

export default imageUtils;
