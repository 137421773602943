import { post } from "./api.service";

const endpoint = "/auth";

interface TokenResponse {
  token: string;
  user: User;
}

const login = (email: string, password: string) =>
  post<TokenResponse>({
    endpoint: endpoint + "/login/email",
    auth: false,
    body: { email, password },
  });

export const AuthApiService = {
  login,
};
