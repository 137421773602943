const constants = {
  headers: [
    {
      accessor: "name",
      label: "Nombre",
    },
    {
      accessor: "points",
      label: "Puntos recompensa",
    },
  ],
  noRewardsYet: "Aun no hay recompensas",
};

export default constants;
