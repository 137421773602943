const constants = {
  title: {
    edit: (negocio?: string) => `Editar ${negocio ?? "admin. de negocio"}`,
    create: "Nuevo admin. de negocio",
    default: "Admin. de negocio",
  },
  errorFetchingStoreUser: "Error al buscar usuario",
  errorUserNotStore: "El usuario no corresponde a un admin de negocio",
  error: (isEdit: boolean) => `Error al ${isEdit ? "editar" : "crear"} admin. de negocio`,
  success: (isEdit: boolean) =>
    `Admin. de negocio ${isEdit ? "editado" : "creado"} correctamente !`,
  buttons: {
    delete: "Eliminar",
    goBack: "Volver",
    save: (isEdit: boolean) => (isEdit ? "Guardar" : "Crear"),
    next: "Siguiente",
  },
  labels: {
    name: "Nombre *",
    email: "Email *",
    password: "Password *",
    store: "Negocio *",
  },
  rules: {
    required: "Este campo es requerido",
    email: "Formato de email incorrecto",
    min8Length: "Minimo 8 caracteres",
    password: "Debe contener al menos 1 caracter y 1 número ",
  },
};

export default constants;

type StoreUserFormInput = {
  name: string;
  email: string;
  password: string;
  storeId: Nullable<string>;
};

export const formDefaultValues: StoreUserFormInput = {
  name: "",
  email: "",
  password: "",
  storeId: "",
};
