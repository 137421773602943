import { Checkbox, Typography } from "@mui/material";
import { FC } from "react";
import { useController } from "react-hook-form";
import { Label } from "../../../../common/components/ui/label/label.component";
import { features } from "./features-select-constants";
import { FormControlLabel, FeatureContainer, FormGroup } from "./features-select.styles";

interface FeaturesSelectProps {
  value: string[];
  setValue: (features: string[]) => void;
  label: string;
}

const FeaturesSelect: FC<FeaturesSelectProps> = ({ value, setValue, label }) => {
  const updateValue = (feature: string, select: boolean) => {
    if (select) {
      setValue([...value, feature]);
    } else {
      setValue(value.filter((v) => v !== feature));
    }
  };

  const isChecked = (feature: string) => {
    return value.includes(feature);
  };

  return (
    <FormGroup>
      <Label>{label}</Label>
      {features.map((feature) => (
        <FormControlLabel
          key={feature.name}
          control={
            <Checkbox
              onChange={(ev) => updateValue(feature.name, ev.target.checked)}
              checked={isChecked(feature.name)}
            />
          }
          label={
            <FeatureContainer>
              {feature.icon}
              <Typography>{feature.label}</Typography>
            </FeatureContainer>
          }
        />
      ))}
    </FormGroup>
  );
};

export default FeaturesSelect;

export function ControlledFeaturesSelect(props: any) {
  const {
    field,
    // fieldState: { error },
  } = useController(props);
  return <FeaturesSelect {...props} value={field.value} setValue={field.onChange} />;
}
