import { Breadcrumbs, Link, Typography } from "@mui/material";
import { useLocation } from "react-router";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "@mui/icons-material/Home";
import { useEffect, useState } from "react";
import { drawerRoutes } from "../drawer/drawer.constants";
import { NavbarContainer } from "./navbar.styles";

const Navbar = () => {
  const location = useLocation();
  const [split, setSplit] = useState<string[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    const [, ...split] = location.pathname.split("/");
    setSplit(split);
  }, [location.pathname]);

  useEffect(() => {
    const labels: string[] = [];
    if (split.length > 0) {
      const firstRoute = drawerRoutes[split[0]];
      if (firstRoute) {
        labels.push(firstRoute.name);
        let nested = firstRoute.nested;
        for (let i = 1; i < split.length && !!nested; i++) {
          labels.push(nested[split[i]]);
          nested = nested.nested;
        }
      } else {
        labels.push("CeliMapp");
      }
    } else {
      labels.push("Dashboard");
    }
    setLabels(labels);
  }, [split]);

  const getColor = (index: number) => {
    return index === split.length - 1 ? "text.primary" : "inherit";
  };

  return (
    <NavbarContainer>
      <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
        <Link underline="hover" color="inherit" href="/">
          <HomeIcon sx={{ marginTop: 0.5 }} />
        </Link>
        {labels.map((label, index) => (
          <Typography key={`breadcumb-${label}-${index}`} color={getColor(index + 1)}>
            {label}
          </Typography>
        ))}
      </Breadcrumbs>
    </NavbarContainer>
  );
};

export default Navbar;
