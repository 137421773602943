import { Avatar, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { RecommendationApiService } from "../../api/recommendation.api.service";
import useLayout from "../../common/hooks/useLayout";
import useSnackbar from "../../common/hooks/useSnackbar";
import { withLayout } from "../../hocs/with-layout";
import NotFoundAnimation from "../not-found/components/not-found-animation.component";
import RecommendationItem from "./components/recommendation-item.component";
import recommendationsDetailsScreenConstants from "./recommendations-details-screen.constants";
import Slider from "react-slick";
import {
  ButtonsContainer,
  FeatureContainer,
  SliderContainer,
} from "./recommendations-details-screen.styles";
import {
  Feature,
  features as featuresData,
} from "../stores-form/components/features-select/features-select-constants";
import { Button } from "../../common/components/ui";
import { dateUtils } from "../../common/utils/date.utils";

const RecommendationsDetailsScreen = () => {
  const { recommendationId } = useParams();
  const { setTitle } = useLayout();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [recommendation, setRecommendation] = useState<Nullable<Recommendation>>(null);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [features, setFeatures] = useState<Feature[]>([]);

  useEffect(() => {
    if (recommendationId) {
      RecommendationApiService.getRecommendationById(recommendationId)
        .then((recommendationRes) => {
          setRecommendation(recommendationRes.data);
          setTitle(recommendationRes.data.name);
          setFeatures(
            featuresData.filter((feat) => recommendationRes.data.features.includes(feat.name))
          );
        })
        .catch((err: any) => {
          if (err.response.status === 404) {
            setNotFound(true);
          } else {
            showSnackbar(recommendationsDetailsScreenConstants.error);
          }
        });
    }
  }, [recommendationId]);

  const discardRecommendation = () => {
    if (recommendationId) {
      RecommendationApiService.discardRecommendationById(recommendationId).then(() => {
        navigate(-1);
      });
    }
  };

  if (notFound) {
    return <NotFoundAnimation />;
  }

  return recommendation ? (
    <Grid container textAlign={"initial"}>
      <Grid item md={6}>
        <RecommendationItem
          label={recommendationsDetailsScreenConstants.labels.name}
          description={recommendation.name}
        />
        <RecommendationItem
          label={recommendationsDetailsScreenConstants.labels.address}
          description={recommendation.address}
        />
        <RecommendationItem
          label={recommendationsDetailsScreenConstants.labels.category}
          description={recommendation.category.name}
        />
        <RecommendationItem
          label={recommendationsDetailsScreenConstants.labels.city}
          description={`${recommendation.city.name} (${recommendation.city.province})`}
        />
        <RecommendationItem
          label={recommendationsDetailsScreenConstants.labels.name}
          description={recommendation.phone}
        />
        <RecommendationItem
          label={recommendationsDetailsScreenConstants.labels.date}
          description={dateUtils.formatDate(recommendation.createdAt)}
        />
        <RecommendationItem
          label={recommendationsDetailsScreenConstants.labels.name}
          description={recommendation.comments}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <RecommendationItem label={recommendationsDetailsScreenConstants.labels.user}>
          <Stack mt={1} direction={"row"} alignItems="center">
            <Avatar src={recommendation.user.picture?.url} />
            <Typography ml={1}>{recommendation.user.name}</Typography>
          </Stack>
        </RecommendationItem>
        <RecommendationItem label={recommendationsDetailsScreenConstants.labels.features}>
          {features.map((feature) => (
            <FeatureContainer key={"feature-" + feature.name}>
              {feature.icon}
              <Typography>{feature.label}</Typography>
            </FeatureContainer>
          ))}
        </RecommendationItem>
        {recommendation.pictures.length > 0 && (
          <RecommendationItem label={recommendationsDetailsScreenConstants.labels.pictures}>
            <SliderContainer>
              <Slider dots={true} infinite={false} speed={500} slidesToShow={1} slidesToScroll={1}>
                {recommendation.pictures.map((pic, val) => (
                  <img alt={"image-" + val} key={"recommendation-image-" + val} src={pic.url} />
                ))}
              </Slider>
            </SliderContainer>
          </RecommendationItem>
        )}
      </Grid>
      <Grid item xs={12}>
        <ButtonsContainer>
          <Button
            text={recommendationsDetailsScreenConstants.discardButton}
            color="error"
            variant="contained"
            onClick={discardRecommendation}
          />
          <Button
            text={recommendationsDetailsScreenConstants.createStoreButton}
            variant="contained"
            onClick={() =>
              navigate("/negocios/crear", {
                state: { recommendation },
              })
            }
          />
        </ButtonsContainer>
      </Grid>
    </Grid>
  ) : (
    <CircularProgress />
  );
};

export default withLayout(RecommendationsDetailsScreen, {
  card: true,
  navbar: true,
  title: recommendationsDetailsScreenConstants.title,
  back: true,
});
