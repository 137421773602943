import { createContext, useEffect, useReducer } from "react";
import { UserApiService } from "../../api/user.api.service";
import storageKeys from "../constants/storage-keys.constants";

const ACTIONS = {
  STORE_USER: "STORE_USER",
  SET_LOADING_USER: "SET_LOADING_USER",
};

export type Action = {
  type: string;
  payload: any;
};

interface AuthContextState {
  user: User | null;
  loadingUser: boolean;
}

interface Actions {
  storeUser: (user: User) => void;
  setLoadingUser: (loading: boolean) => void;
  logout: () => void;
}

const initialState: AuthContextState = {
  user: null,
  loadingUser: true,
};

const actions: Actions = {
  storeUser: (_: User) => {},
  setLoadingUser: (_: boolean) => {},
  logout: () => {},
};

const userReducer = (state: AuthContextState, action: Action): AuthContextState => {
  switch (action.type) {
    case ACTIONS.STORE_USER:
      return { ...state, user: action.payload };
    case ACTIONS.SET_LOADING_USER:
      return { ...state, loadingUser: action.payload };
    default:
      return state;
  }
};

export const AuthContext = createContext({ ...initialState, ...actions });

const AuthContextProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  useEffect(() => {
    const init = () => {
      let token = localStorage.getItem(storageKeys.userToken);
      if (token) {
        UserApiService.getDetails()
          .then((res) => {
            storeUser(res.data);
            setLoadingUser(false);
          })
          .catch((err) => {
            console.log(err);
            setLoadingUser(false);
          });
      } else {
        setLoadingUser(false);
      }
    };
    init();
  }, []);

  const storeUser = (user: User | null) => {
    dispatch({
      type: ACTIONS.STORE_USER,
      payload: user,
    });
  };

  const setLoadingUser = (loading: boolean) => {
    dispatch({
      type: ACTIONS.SET_LOADING_USER,
      payload: loading,
    });
  };

  const logout = () => {
    storeUser(null);
    localStorage.removeItem(storageKeys.userToken);
  };

  return (
    <AuthContext.Provider value={{ ...state, storeUser, setLoadingUser, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
