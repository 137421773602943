import { post } from "./api.service";

const endpoint = "/notifications";

export interface NotificationBody {
  title: string;
  storeId: Nullable<string>;
  description: string;
  cityId: string;
  image: Nullable<Image>;
}

const createNotification = (body: NotificationBody) =>
  post({
    endpoint,
    auth: true,
    body,
  });

export const NotificationApiService = {
  createNotification,
};
