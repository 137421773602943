import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const AppContainerBox = styled(Box)`
  justify-content: center !important;
  text-align: center !important;
`;

export const RoutesContainerBox = styled(Box)`
  display: flex;
`;

export const ScreenContainer = styled(Box)`
  flex: 1;
  margin-right: 10%;
`;
