import { Stack } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router";
import { Button, Table } from "../../../../common/components/ui";
import storeUsersTableConstants from "./store-users-table.constants";
import { StoreAvatar } from "./store-users-table.styles";

interface StoreUsersTableProps {
  total: number;
  users: User[];
  loading: boolean;
}

const StoreUsersTable: FC<StoreUsersTableProps> = ({ total, users, loading }) => {
  const navigate = useNavigate();
  return (
    <Table
      data={users.map((user) => ({
        store: (
          <Stack flexDirection={"row"} alignItems="center">
            {user.myStore?.logo ? (
              <StoreAvatar src={user.myStore.logo.url} />
            ) : (
              <StoreAvatar>{user.myStore?.name[0] ?? user.name[0]}</StoreAvatar>
            )}
            {user.myStore?.name}
          </Stack>
        ),
        name: user.name,
        email: user.email,
        actions: (
          <Button
            onClick={() => navigate("/admin-negocios/editar/" + user.id)}
            text={storeUsersTableConstants.editButton}
            variant={"text"}
          />
        ),
      }))}
      headers={storeUsersTableConstants.headers}
      loading={loading}
      total={total}
      constants={{
        noDataYet: storeUsersTableConstants.noDataYet,
        noResults: storeUsersTableConstants.noResults,
      }}
      keyName={"store-users"}
    />
  );
};

export default StoreUsersTable;
