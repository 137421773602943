import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

export const ButtonContainer = styled("div")({});

export const CustomButton = styled(Button)`
  width: fit-content;
  /* .root {
    text-transform: none !important;
    border-radius: 50px !important;
  }
  .MuiButton-root {
    text-transform: none !important;
    border-radius: 50px !important;
  } */
`;

// export const CustomButton = styled(Button)(
// ({ itemProp }) => `
//   width: ${width ?? '150px'};
// `);
