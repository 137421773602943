const constants = {
  labels: {
    name: "Nombre *",
    description: "Descripción *",
    category: "Categoria *",
    province: "Provincia *",
    city: "Ciudad *",
    features: "Características",
    logo: "Logo *",
    cover: "Cover *",
    images: "Imágenes",
    address: "Dirección",
    hours: "Horarios",
    phoneNumber: "Teléfono",
    email: "Email de contacto",
    webpage: "Sitio web",
    socials: "Redes Sociales",
    coordinatesLat: "Coord. Latitud *",
    coordinatesLon: "Coord. Longitud *",
    active: "Activo",
  },
  caption: {
    location1: "Buscar (direccion, ciudad. Por ejemplo: yrigoyen 120, bahia blanca) y dar ENTER",
    location2: "Luego, elegir un resultado y se autocompletarán los campos.",
  },
  search: "Buscar",
  searching: "Buscando direccion...",
  comingSoon: "Próximamente",
};

export default constants;
