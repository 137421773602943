import StoresFormStepOne from "./components/stores-form-steps/stores-form-step-one.component";
import StoresFormStepTwo from "./components/stores-form-steps/stores-form-step-two.component";
import StoresFormStepThree from "./components/stores-form-steps/stores-form-step-three.component";
import StoresFormStepFour from "./components/stores-form-steps/stores-form-step-four.component";

const constants = {
  title: {
    edit: (negocio?: string) => `Editar ${negocio ?? "Negocio"}`,
    create: "Nuevo negocio",
    default: "Negocio",
  },
  errorFetchingStore: "Error al buscar negocio",
  error: (isEdit: boolean) => `Error al ${isEdit ? "editar" : "crear"} negocio`,
  success: (isEdit: boolean) => `Negocio ${isEdit ? "editado" : "creado"} correctamente !`,
  buttons: {
    goBack: "Volver",
    save: (isEdit: boolean) => (isEdit ? "Guardar" : "Crear"),
    next: "Siguiente",
  },
};

export default constants;

export const storesFormSteps: Step[] = [
  { component: StoresFormStepOne, label: "Negocio" },
  { component: StoresFormStepTwo, label: "Ubicación" },
  { component: StoresFormStepThree, label: "Imágenes" },
  { component: StoresFormStepFour, label: "Detalle de contacto" },
];
