const constants = {
  title: "Recomendación",
  error: "Recomendación no encontrada",
  labels: {
    name: "Nombre",
    address: "Direccion",
    category: "Categoria",
    city: "Ciudad",
    phone: "Teléfono",
    date: "Fecha de creación",
    comments: "Comentarios",
    user: "Usuario",
    features: "Características",
    pictures: "Imágenes",
  },
  createStoreButton: "Crear negocio",
  discardButton: "Descartar",
};
export default constants;
