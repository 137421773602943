import { Search } from "@mui/icons-material";
import { FormControlLabel, FormGroup, InputAdornment, Stack, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, TextField } from "../../common/components/ui";
import { withLayout } from "../../hocs/with-layout";
// import StoresTable from "./components/stores-table/stores-table.component";
import { CouponApiService } from "../../api/coupon.api.service";
import { CouponsContainer } from "./coupons-screen.styles";
import couponsScreenConstants from "./coupos-screen.constants";
import CouponsTable from "./components/coupons-table/coupons-table.component";
import { couponUtils } from "../../common/utils/coupon.utils";

const CouponsScreen = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [filteredCoupons, setFilteredCoupons] = useState<Coupon[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showExpired, setShowExpired] = useState<boolean>(false);

  useEffect(() => {
    CouponApiService.queryCouopns().then((couponRes) => {
      setCoupons(couponRes.data.results);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setFilteredCoupons(coupons.filter((c) => !couponUtils.isExpired(c)));
  }, [coupons]);

  useEffect(() => {
    if (search) {
      setFilteredCoupons(
        coupons.filter(
          (c) =>
            (showExpired || !couponUtils.isExpired(c)) &&
            c.store.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      if (showExpired) {
        setFilteredCoupons(coupons);
      } else {
        setFilteredCoupons(coupons.filter((c) => !couponUtils.isExpired(c)));
      }
    }
  }, [search, showExpired]);

  return (
    <CouponsContainer>
      <Stack direction={"row"} justifyContent="space-between">
        <TextField
          sx={{ width: 400 }}
          size="small"
          value={search}
          setValue={setSearch}
          placeholder={couponsScreenConstants.searchPlaceholder}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Switch onChange={(ev) => setShowExpired(ev.target.checked)} checked={showExpired} />
            }
            label={couponsScreenConstants.showExpired}
          />
        </FormGroup>

        <Button
          onClick={() => navigate("/cupones/crear")}
          text={couponsScreenConstants.createStore}
          variant="contained"
        />
      </Stack>
      <Stack mt={5}>
        <CouponsTable total={coupons.length} coupons={filteredCoupons} loading={loading} />
      </Stack>
    </CouponsContainer>
  );
};

export default withLayout(CouponsScreen, {
  title: couponsScreenConstants.title,
  card: true,
  navbar: true,
});
