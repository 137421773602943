import beerIcon from "./features/beer.svg";
import pastaIcon from "./features/pasta.svg";
import breadIcon from "./features/bread.svg";
import dessertIcon from "./features/dessert.svg";
import glutenFreeIcon from "./features/gluten-free.svg";
import inPlaceIcon from "./features/in-place.svg";
import deliveryIcon from "./features/delivery.svg";
import { Feature as FeatureComp } from "./features-select.styles";

const FeatureIcon = (svg: any) => {
  return <FeatureComp src={svg.svg} />;
};

export type Feature = {
  icon: JSX.Element;
  name: string;
  label: string;
};

export const features: Feature[] = [
  { icon: <FeatureIcon svg={beerIcon} />, name: "beer", label: "Tiene cerveza apta" },
  { icon: <FeatureIcon svg={pastaIcon} />, name: "pasta", label: "Tiene pastas aptas" },
  { icon: <FeatureIcon svg={breadIcon} />, name: "bread", label: "Tiene pan apto" },
  { icon: <FeatureIcon svg={dessertIcon} />, name: "dessert", label: "Tiene postre apto" },
  {
    icon: <FeatureIcon svg={glutenFreeIcon} />,
    name: "gluten-free",
    label: "Exclusivo sin gluten",
  },
  { icon: <FeatureIcon svg={inPlaceIcon} />, name: "in-place", label: "Consumir en el lugar" },
  { icon: <FeatureIcon svg={deliveryIcon} />, name: "delivery", label: "Tiene delivery" },
];
