import {
  Autocomplete,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { GoogleGeocoderResult, LocationApiService } from "../../../../api/location.api.service";
import { ControlledFormInput, ControlledFormSelect } from "../../../../common/components/ui";
import useStoresForm from "../../../../common/hooks/useStoresForm";
import locationUtils from "../../../../common/utils/location.utils";
import { StoresFormStepProps } from "../../stores-form-screen.component";
import storesFormStepsConstants from "./stores-form-steps.constants";

const StoresFormStepTwo: FC<StoresFormStepProps> = () => {
  const { provinces, cities } = useStoresForm();
  const { control, setValue } = useFormContext();
  const [searchResults, setSearchResults] = useState<GoogleGeocoderResult[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [fetching, setFetching] = useState<boolean>(false);

  const fetchResults = () => {
    setFetching(true);
    LocationApiService.searchLocation(searchText)
      .then((res) => setSearchResults(res.data))
      .finally(() => {
        setFetching(false);
      });
  };

  const updateLocation = (value: GoogleGeocoderResult) => {
    LocationApiService.findLocation(value.geometry.location).then((res) => {
      setValue("provinceId", res.data.provinceId);
      setValue("cityId", res.data.id);
      setValue("coordinates.lat", value.geometry.location.lat);
      setValue("coordinates.lon", value.geometry.location.lng);
      setValue("address", locationUtils.getGoogleResultAddressFormat(value));
    });
  };

  return (
    <Grid container mt={3}>
      <Grid item xs={11} mb={2}>
        <Autocomplete
          inputValue={searchText}
          onInputChange={(_, vale) => {
            setSearchText(vale);
          }}
          loadingText={storesFormStepsConstants.searching}
          loading={fetching}
          getOptionLabel={(option) => option.formatted_address}
          renderInput={(params) => (
            <TextField
              {...params}
              label={storesFormStepsConstants.search}
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {fetching ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          // @ts-ignore
          options={searchResults}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              fetchResults();
            }
          }}
          filterOptions={(x) => x}
          onChange={(_: any, newValue: GoogleGeocoderResult | null) => {
            if (newValue) {
              updateLocation(newValue);
            }
          }}
        />
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>
          <Typography variant="caption">{storesFormStepsConstants.caption.location1}</Typography>
          <Typography variant="caption">{storesFormStepsConstants.caption.location2}</Typography>
        </div>
        <Divider sx={{ marginTop: 2 }} />
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid item xs={12} sm={10} mb={2}>
          <ControlledFormSelect
            name="provinceId"
            control={control}
            label={storesFormStepsConstants.labels.province}
            rules={{
              required: "Este campo es requerido",
            }}
          >
            {provinces.map((province) => (
              <MenuItem key={"province-" + province.id} value={province.id}>
                {province.name}
              </MenuItem>
            ))}
          </ControlledFormSelect>
        </Grid>
        <Grid item xs={12} sm={10} mb={2}>
          <ControlledFormSelect
            name="cityId"
            control={control}
            label={storesFormStepsConstants.labels.city}
            rules={{
              required: "Este campo es requerido",
            }}
          >
            {cities.map((city) => (
              <MenuItem key={"city-" + city.id} value={city.id}>
                {city.name}
              </MenuItem>
            ))}
          </ControlledFormSelect>
        </Grid>
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={10} md={5} mb={2}>
            <ControlledFormInput
              name="coordinates.lat"
              control={control}
              rules={{
                required: "Este campo es requerido",
              }}
              type="number"
              label={storesFormStepsConstants.labels.coordinatesLat}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={5} mb={2}>
            <ControlledFormInput
              name="coordinates.lon"
              control={control}
              rules={{
                required: "Este campo es requerido",
              }}
              type="number"
              label={storesFormStepsConstants.labels.coordinatesLon}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={10} mb={2}>
          <ControlledFormInput
            name="address"
            control={control}
            rules={{
              required: "Este campo es requerido",
            }}
            label={storesFormStepsConstants.labels.address}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StoresFormStepTwo;
