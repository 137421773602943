import { get, post } from "./api.service";

const endpoint = "/reviews";

const getReviewsToManage = () => get<UserReview[]>({ endpoint: endpoint + "/manage", auth: true });

const getReviewByIdToManage = (id: string) =>
  get<UserReview>({ endpoint: endpoint + "/manage/" + id, auth: true });

const approveReview = (reviewId: string) =>
  post({ endpoint: endpoint + "/" + reviewId + "/approve", body: {}, auth: true });

const rejectReview = (reviewId: string) =>
  post({ endpoint: endpoint + "/" + reviewId + "/reject", body: {}, auth: true });

export const ReviewsApiService = {
  getReviewsToManage,
  getReviewByIdToManage,
  approveReview,
  rejectReview,
};
