import { Stack, Typography } from "@mui/material";
import { FC } from "react";

interface ReviewItemProps {
  label: string;
  description?: string | React.ReactNode;
}

const ReviewItem: FC<ReviewItemProps> = ({ label, description, ...props }) => {
  return (
    <Stack m={1}>
      <Typography fontWeight={"bold"} variant="subtitle2">
        {label}
      </Typography>
      {description &&
        (typeof description === "string" ? (
          <Typography ml={1} variant="subtitle1">
            {description}
          </Typography>
        ) : (
          description
        ))}
      {props.children}
    </Stack>
  );
};

export default ReviewItem;
