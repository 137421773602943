import Lottie from "lottie-react";
import notFoundAnimation from "./not-found-animation.json";

const NotFoundAnimation = () => {
  return (
    <Lottie
      animationData={notFoundAnimation}
      loop={true}
      style={{
        height: "500px",
      }}
    />
  );
};

export default NotFoundAnimation;
