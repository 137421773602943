import { ThemeProvider } from "@mui/material/styles";
import AuthContextProvider from "./common/context/auth.context";
import SnackbarContextProvider from "./common/context/snackbar.context";
import { theme } from "./common/theme/theme";
import AppRouter from "./router/app-router/app-router.component";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarContextProvider>
        <AuthContextProvider>
          <AppRouter />
        </AuthContextProvider>
      </SnackbarContextProvider>
    </ThemeProvider>
  );
}

export default App;
