const constants = {
  headers: [
    {
      accessor: "store",
      label: "Negocio",
    },
    {
      accessor: "name",
      label: "Nombre",
    },
    {
      accessor: "email",
      label: "Email",
    },
    {
      accessor: "actions",
      label: "Acciones",
    },
  ],
  noDataYet: "Aun no hay usuarios",
  noResults: "No existen usuarios para el negocio",
  editButton: "EDITAR",
};

export default constants;
