import { styled } from "@mui/material/styles";

export const HomeImage = styled("img")`
  display: inline;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  ${(props) => props.theme.breakpoints.down("md")} {
    height: 40vh;
  }
`;

export const Logo = styled("img")`
  margin-bottom: 30px;
  ${(props) => props.theme.breakpoints.down("md")} {
    margin-top: 50px;
  }
`;

export const TextFieldContainer = styled("div")`
  margin: 10px 0;
  width: 340px;
`;

export const ButtonContainer = styled("div")`
  margin: 20px 0;
`;
